<template>
  <div class="page-content">
    <h3 class="mb-2 mb-md-0" style="display: flex; align-items: center;">
      <router-link :to="{
        name: 'ActiveTests',
        query: {
          id: this.routesearch ? encode(this.routesearch) : '',
          offset: this.routeoffset ? encode(this.routeoffset) : '',
          current: this.routecurrent ? encode(this.routecurrent) : '',
          filter: true,
        },
      }" class="p-0" type="button">
        <img src="../../../public/dashboard-assets/images/M2/BackButton.png" alt="Back Button"
          style="margin-right: 10px;" />
      </router-link>
      <div>
        <div class="mb-1">{{ this.editTestName }}</div>
        <div style="color: grey; font-size: small;">TestId:{{ this.routetestId }}</div>
      </div>
    </h3>
    <!-- Navigation Pill Start -->
    <div class="row mt-2 mx-3">
      <div class="col-10">
        <ul class="nav nav-pills mb-3 partners_top_menu_section_hp mt-4 user_tabing_nav_section" id="pills-tab"
          role="tablist">
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3 mx-2" :class="this.overviewStatus ? 'active' : ''" id="overview-tab"
              data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview"
              aria-selected="true" @click="changeTab('overview')">
              Overview
            </button>
          </li>
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3" :class="this.generalStatus ? 'active' : ''" id="general-tab"
              data-bs-toggle="pill" data-bs-target="#general" type="button" role="tab" aria-controls="general"
              aria-selected="true" @click="changeTab('general')">
              General Settings
            </button>
          </li>
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3" :class="this.TestTimeStatus ? 'active' : ''" id="test-time-activation-tab"
              data-bs-toggle="pill" data-bs-target="#test-time-activation" type="button" role="tab"
              aria-controls="test-time-activation" aria-selected="false" @click="changeTab('test-time')">
              Test and Time Activation
            </button>
          </li>
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3" :class="this.evaStatus ? 'active' : ''" id="eva-tab" data-bs-toggle="pill"
              data-bs-target="#eva" type="button" role="tab" aria-controls="eva" aria-selected="false"
              @click="changeTab('eva')">
              eVA Settings
            </button>
          </li>
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3" :class="this.advanceStatus ? 'active' : ''" id="advance-tab"
              data-bs-toggle="pill" data-bs-target="#advance" type="button" role="tab" aria-controls="advance"
              aria-selected="false" @click="changeTab('advance')">
              Advance Settings
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- Navigation Pill End -->

    <!-- Overview Start-->
    <div class="row mt-4" v-if="this.overviewStatus">
      <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-12 mb-4">
        <div class="card p-4" style="border-radius: 25px; width: 100%; height: 100%;">
          <div class="card-body p-0">
            <div class="active-list-invite-kj">
              <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                <span>Test Time
                </span>
                <!-- <span>{{ this.testTime }}min</span> -->
                <span>{{ dateConvert(this.testSummary.tesTime) }}</span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                <span>Candidates
                </span>
                {{ this.testSummary.completed }}/{{
                this.testSummary.scheduled
                }}
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                <span>Start Date
                </span>
                <span v-if="this.testSummary.startTime != null">{{
                  this.testSummary.startTime
                  }}</span>
                <span v-else>NaN</span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                <span>End Date
                </span>
                <span v-if="this.testSummary.endTime != null">{{
                  this.testSummary.endTime
                  }}</span>
                <span v-else>NaN</span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                <span>Cooling Period
                </span>
                <span v-if="this.testSummary.coolingPeriodDays != null">
                  {{ this.testSummary.coolingPeriodDays }} Days
                </span>
                <span v-else>NaN</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-8 col-md-8 col-sm-12 mb-4">
        <div class="card p-2" style="border-radius: 25px;">
          <div class="card-body p-0">
            <div class="table-responsive mb-4" style="width: 100%; height: 300px;">
              <table class="table">
                <thead>
                  <tr>
                    <th>Test Sections</th>
                    <th>No. of Questions</th>
                    <th>Absolute Benchmark(%)</th>
                    <th>Weightage</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in this.testSection" :key="item">
                    <td> {{ item.sectionName }}</td>
                    <td>{{ item.noOfQuestion }}</td>
                    <td>{{ item.passingScore }}</td>
                    <td v-if="item.weightageValue != null"> {{ item.weightageValue }} </td>
                    <td v-else>NaN</td>
                    <td> {{ item.sectionTime.split(":", 2) }}min</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Overview End -->

    <!-- General Settings Start -->
    <div v-if="superadmin() && this.generalStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Edit Test</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>


        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="row mt-4">
              <div class="col-6">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <input type="text" class="form-control" id="start-time" v-model="this.editTestName"
                      :maxlength="75" />
                    <span class="input-name">
                      Test Name
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="my-3 test_instance_button row" v-if="editTestName !== testSummary.testName">
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GeneralData()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.UpdateTestName()"> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="superadmin() && this.generalStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Anti Cheating Settings</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-6 mt-2">
                <div class="col">
                  <div class="d-flex flex-wrap">
                    <div class="me-4">
                      <div>Web Proctoring</div>
                      <button @click="toggleWebProctor" :class="{ 'toggle-button': true, 'active': webProctorStatus }"
                        class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Video Proctoring</div>
                      <button @click="toggleVideoProctor" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': videoProctorStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Picture Capture</div>
                      <button @click="togglepictureCapture" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': pictureCaptureStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Video Capture</div>
                      <button @click="toggleVideoCapture" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': videoCaptureStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Audio Capture</div>
                      <button @click="toggleAudioCapture" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': audioCaptureStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Logs Enabled</div>
                      <button @click="toggleLogsEnabled" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': logsEnabledStatus }" class="mt-2"></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="my-3 test_instance_button row">
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GetProctorSettingData()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.PutProctorSettingsData()"> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- General Settings End -->

    <!-- Test and Time Activation Start -->
    <div v-if="superadmin() && this.TestTimeStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Schedule Test</h4>
              <h5 style="color: #888888;" class="mt-2">Schedule test as per your requirements</h5>
            </div>
            <button @click="toggleCollapse" :class="{ 'toggle-button': true, 'active': TestDurationStatus }"
              class="ms-2"></button>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse" :class="{ show: TestDurationStatus }"
          aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="row mt-4">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <input type="datetime-local" class="form-control" id="start-time" :min="this.minDateTime"
                      v-model="scheduledStartTime" :disabled="TestDurationStatus ? false : true" />
                    <span class="input-name">
                      Start Time
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <input type="datetime-local" class="form-control" id="end-time" :min="this.minDateTime"
                      v-model="scheduledEndTime" :disabled="TestDurationStatus ? false : true"
                      :close-on-select="ConvertedEndTime == null" />
                    <span class="input-name">
                      End Time
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                v-if="this.user.accountLocation != this.user.countryCode">
                <div class="form-group mb-4">
                  <label class="input-hero">
                    <select class="form-select input_area select_ca" v-model="TimeZoneValue">
                      <!-- <option disabled selected>
                      Select Preferred Time Zone
                    </option> -->
                      <option v-for="item in GetTimeZoneData.object.data" :key="item" :value="item.gmtOffsetName">
                        {{ item.zoneName }} {{ item.gmtOffsetName }}
                      </option>
                    </select>
                    <span class="input-name">
                      Timezone
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="my-3 test_instance_button row">
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.resetData()"
                  :disabled="TestDurationStatus == false">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" :disabled="!this.scheduleTestShow"
                  @click="this.UpdateTestDurationData()"> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Test and Time Activation End -->

    <!-- eVA Settings Start -->

    <div v-if="superadmin() && this.evaStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Language Weightage</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div class="col">
                  <div class="table-responsive mb-4">
                    <table class="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th v-for="parameter in parameters" :key="parameter.parameterName">{{ parameter.parameterName
                            }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="section in sectionNames" :key="section.sectionId">
                          <td>{{ section.sectionName }}</td>
                          <td v-for="parameter in parameters" :key="parameter.parameterName">
                            <span>{{ getWeightage(section.sectionName, parameter.parameterName) }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="superadmin() && this.evaStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Language Preferences</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div>
                  <!-- <h4 style="font-weight: 600;">Text Preferences</h4> -->
                  <div class="row">
                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-3"
                      v-for="(item, index) in textPreferences" :key="item.preferenceName">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <input type="text" class="form-control" v-model="item.preferenceValue" :maxlength="75"
                            @input="markChanged(index)" />
                          <span class="input-name">{{ item.preferenceLabel }}</span>
                        </label>
                      </div>
                      <div class="d-flex justify-content-end mt-2">
                        <button class="btn btn-primary" @click="updatePreference(item)"
                          v-if="changedPreferences[index]">
                          Update
                        </button>
                      </div>
                    </div>

                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-3" v-for="item in boolPreferences"
                      :key="item.preferenceName">
                      <div class="d-flex flex-wrap">
                        <div class="me-4">
                          <div>{{ item.preferenceLabel }}</div>
                          <button @click="togglePreference(item)"
                            :class="{ 'toggle-button': true, 'active': item.preferenceValue }" class="mt-2">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-3" v-for="(item, index) in dropPreferences"
                    :key="item.preferenceName">
                    <div class="d-flex flex-wrap">
                      <div class="me-4">
                        <div>{{ item.preferenceLabel }}</div>
                        <div class="select-container mt-2">
                          <select v-model="item.preferenceValue" class="custom-select" @change="dropChanged(index)">
                            <option value="en-US">English (US)</option>
                            <option value="en-IN">English (India)</option>
                            <option value="en-GB">English (GB)</option>
                            <option value="hi-IN">Hindi (India)</option>
                            <option value="es-ES">Spanish (ES)</option>
                            <option value="ja-JP">Japan (JP)</option>
                            <option value="en-PH">English (Philippines)</option>

                          </select>
                          <span class="select-icon">&#9662;</span>
                        </div>
                        <div class="d-flex justify-content-end mt-2">
                          <button class="btn btn-primary" @click="updatePreference(item)"
                            v-if="changedDropdownPreferences[index]">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="superadmin() && this.evaStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Language Preferences</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div class="col">
                  <div class="table-responsive mb-4">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Api Name</th>
                          <th>Is Default Language</th>
                          <th>Language Name</th>
                          <th>Question Type Name</th>
                          <th>Section Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select">
                                  <option value="google">Google</option>
                                  <option value="azure">Azure</option>
                                  <option value="gpt">GPT</option>
                                  <option value="meta">Meta</option>
                                  <option value="context">Context</option>
                                  <option value="sales">Sales</option>
                                  <option value="ettiquete">Ettiquete</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="me-4">
                              <button :class="{ 'toggle-button': true, 'active': preferenceValue }" class="mt-2">
                              </button>
                            </div>
                          </td>
                          <td>
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select">
                                  <option value="en-US">English (US)</option>
                                  <option value="en-IN">English (India)</option>
                                  <option value="en-GB">English (GB)</option>
                                  <option value="hi-IN">Hindi (India)</option>
                                  <option value="es-ES">Spanish (ES)</option>
                                  <option value="ja-JP">Japan (JP)</option>
                                  <option value="en-PH">English (Philippines)</option>

                                  <option value="fill_blank">Fill Blank</option>
                                  <option value="story">Story</option>
                                  <option value="relevance">Relevance</option>
                                  <option value="customer">Customer</option>
                                  <option value="solution">Solution</option>

                                  <option value="chat">Chat</option>
                                  <option value="email">Email</option>


                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select">
                                  <option value="google">Google</option>
                                  <option value="azure">Azure</option>
                                  <option value="gpt">GPT</option>
                                  <option value="meta">Meta</option>
                                  <option value="context">Context</option>
                                  <option value="sales">Sales</option>
                                  <option value="ettiquete">Ettiquete</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select" >
                                  <option v-for="item in this.testSection" :key="item"> {{ item.sectionName }}</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- eVA Settings End -->

    <!-- Advance Settings Start -->

    <div v-if="superadmin() && this.advanceStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Test Preference</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div class="col">
                  <div class="d-flex flex-wrap">
                    <div class="me-4">
                      <div>Behavioural Interview Question</div>
                      <button @click="toggleIsBehaviouralInterviewQuestionEnabled()"
                        :class="{ 'toggle-button': true, 'active': IsBehaviouralInterviewQuestionEnabledStatus }"
                        class="mt-2">
                      </button>
                    </div>
                    <div class="me-4">
                      <div>Manual Evaluation</div>
                      <button @click="toggleIsEvaluationEnabled()"
                        :class="{ 'toggle-button': true, 'active': IsEvaluationEnabledStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Managerial Reccomendation</div>
                      <button @click="toggleIsManagerialReccomendationEnabled()"
                        :class="{ 'toggle-button': true, 'active': IsManagerialReccomendationEnabledStatus }"
                        class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Show Answer</div>
                      <button @click="toggleIsShowAnswerSheet()"
                        :class="{ 'toggle-button': true, 'active': IsShowAnswerSheetStatus }" class="mt-2"></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- 
            <div class="my-3 test_instance_button row" >
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GetAdvanceSettings()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.putProctorSettings()"> Update
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="superadmin() && this.advanceStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Report Setting</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div class="col">
                  <div class="d-flex flex-wrap">
                    <div class="me-4 mx-2">
                      <div>Apti Shorten</div>
                      <button @click="toggleAptiShortenStatus()"
                        :class="{ 'toggle-button': true, 'active': aptiShortenStatus }" class="mt-2">
                      </button>
                    </div>
                    <div class="me-4 mx-2">
                      <div>Apti Detailed</div>
                      <button @click="toggleAptiDetailedStatus()"
                        :class="{ 'toggle-button': true, 'active': aptiDetailedStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4 mx-2">
                      <div>Psy Shorten</div>
                      <button @click="togglePsyShortenStatus()"
                        :class="{ 'toggle-button': true, 'active': psyShortenStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4 mx-2">
                      <div>Psy Detailed</div>
                      <button @click="togglePsyDetailedStatus()"
                        :class="{ 'toggle-button': true, 'active': psyDetailedStatus }" class="mt-2"></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="my-3 mt-5  test_instance_button row">
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GetReportSettingsData()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.PutReportSettingsDetails()"> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="superadmin() && this.advanceStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Candidate Additional Details</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <!-- Mayuresh -->
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead style="background-color: #F1F0FF !important;">
                        <tr>
                          <th style="max-width: 150px; word-wrap: break-word; white-space: normal;">Field Name</th>
                          <th>Field Type</th>
                          <th>IsMandatory</th>
                          <th style="max-width: 300px; word-wrap: break-word; white-space: normal;">Options</th>
                          <th style="min-width: 240px; word-wrap: break-word; white-space: normal;">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><input v-model="postFieldName"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <div class="select-container" style="height: 100%; min-height: 40px;">
                              <select v-model="postFieldType" class="custom-select">
                                <option value="DateofBirth">Date of Birth</option>
                                <option value="Number">Number</option>
                                <option value="Text">Text</option>
                                <option value="Date">Date</option>
                                <option value="File">File</option>
                                <option value="Dropdown">Dropdown</option>
                                <option value="PAN">PAN</option>
                                <option value="Aadhaar">Aadhaar</option>
                              </select>
                              <span class="select-icon">&#9662;</span>
                            </div>
                          </td>
                          <td>
                            <button @click="postToggleCollapse"
                              :class="{ 'toggle-button': true, 'active': postMandatoryStatus }" class="ms-2"></button>
                          </td>
                          <td v-if="postFieldType == 'Dropdown'"><input v-model="postOptions"
                              style="width:100%; max-width: 400px; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td v-else>

                          </td>
                          <td>
                            <span @click="PostCandidateAdditionalField()" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span @click="ResetCandidateAdditionalField()" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Reset</span><i class="fa-solid fa-ban fa-lg"></i> </span>

                          </td>
                        </tr>
                        <tr v-for="item in AdvanceCandidateDetails" :key="item.cadmid">
                          <td style="max-width: 150px; word-wrap: break-word; white-space: normal;">
                            <span v-if="!item.editMode">{{ item.fieldName }}</span>
                            <input v-else v-model="item.fieldName"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <span v-if="!item.editMode">{{ item.fieldType }}</span>
                            <div v-else class="select-container">
                              <select v-model="item.fieldType" class="custom-select">
                                <option value="DateofBirth">Date of Birth</option>
                                <option value="Number">Number</option>
                                <option value="Text">Text</option>
                                <option value="Date">Date</option>
                                <option value="File">File</option>
                                <option value="Dropdown">Dropdown</option>
                                <option value="PAN">PAN</option>
                                <option value="Aadhaar">Aadhaar</option>
                              </select>
                              <span class="select-icon">&#9662;</span>
                            </div>
                          </td>
                          <td>
                            <button @click="toggleIsMandatory(item)"
                              :class="{ 'toggle-button': true, 'active': item.isMandatory }"
                              class="mandatorybutton ms-2" :disabled="!item.editMode">
                              <!-- {{ item.isMandatory ? 'True' : 'False' }} -->
                            </button>
                          </td>
                          <td v-if="item.fieldType == 'Dropdown'"
                            style="max-width: 300px; word-wrap: break-word; white-space: normal;">
                            <span v-if="!item.editMode"
                              style="display: inline-block; max-height: 100px; overflow: scroll;">{{ item.options
                              }}</span>
                            <input v-else v-model="item.options"
                              style="width:100%; max-width: 400px; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td v-else>

                          </td>
                          <td style="max-width: 240px; word-wrap: break-word; white-space: normal;">
                            <span v-if="!item.editMode" @click="editItem(item)" class="btn btn-primary"
                              style=" font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Edit</span><i class="fa-regular fa-pen-to-square fa-lg"></i></span>
                            <span v-else @click="saveItem(item)" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span v-if="item.editMode" @click="cancelEdit(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Cancel</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                            <span v-else @click="deleteItem(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Delete</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                            <!-- <button v-if="!item.editMode" @click="editItem(item)">Trust</button>
          <button v-else @click="saveItem(item)">Save</button>
          <button v-if="item.editMode" @click="cancelEdit(item)">Cancel</button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="superadmin() && this.advanceStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Test Sections</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead style="background-color: #F1F0FF !important;">
                        <tr>
                          <th style="max-width: 150px; word-wrap: break-word; white-space: normal;">Section Name</th>
                          <th>Max Score</th>
                          <th>Min Score</th>
                          <th style="max-width: 300px; word-wrap: break-word; white-space: normal;">Weightage Value</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="item in TestSectionsDetails" :key="item.testId">
                          <td style="max-width: 150px; word-wrap: break-word; white-space: normal;">
                            <span>{{ item.sectionName }}</span>
                          </td>
                          <td>
                            <span v-if="!item.editTestSectionMode">{{ item.maxScore }}</span>
                            <input v-else v-model="item.maxScore"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <span v-if="!item.editTestSectionMode">{{ item.minScore }}</span>
                            <input v-else v-model="item.minScore"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <span v-if="!item.editTestSectionMode">{{ item.weightageValue }}</span>
                            <input v-else v-model="item.weightageValue"
                              style="width:100%; max-width: 400px; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" />
                            <span v-if="item.sectionName === null">{{ totalWeightageValue }}</span>
                          </td>

                          <td style="max-width: 300px; word-wrap: break-word; white-space: normal;">
                            <span v-if="!item.editTestSectionMode" @click="editTestSectionItem(item)"
                              class="btn btn-primary"
                              style=" font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Edit</span><i class="fa-regular fa-pen-to-square fa-lg"></i></span>
                            <span v-else @click="saveTestSectionItem(item)" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span v-if="item.editTestSectionMode" @click="cancelTestSectionEdit(item)"
                              class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Cancel</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                            <span v-else @click="deleteTestSectionItem(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Delete</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Advance Settings End -->
    <div class="modal fade" id="DurationStatus" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            BLA BLA BLA BLA
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="patchDuration">
                Deactive
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="AdvanceValidation" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            {{ this.advanceModalTitle }}
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal"
                @click="this.ResetPutAdvanceSettings()">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="this.PutAdvanceSettings()">
                {{ this.advanceModalStatus ? "Active" : " Deactive" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="PreferenceValidation" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            {{ this.preferenceModalTitle }}
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal"
                @click="this.ResetTogglePreference()">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="this.updatePreferenceData()">
                {{ this.preferenceModalStatus ? "Active" : " Deactive" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { superadmin } from "../../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "ActiveTestEditInstance",
  components: {

  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),

    totalWeightageValue() {
      return this.TestSectionsDetails.reduce((sum, item) => {
        return sum + (Number(item.weightageValue) || 0);
      }, 0);
    },
    sectionNames() {
      return this.model;
    },
    parameters() {
      const paramSet = new Set();
      this.sectionNames.forEach(section => {
        section.languageParameters.forEach(param => {
          paramSet.add(param.parameterName);
        });
      });
      return Array.from(paramSet).map(paramName => ({ parameterName: paramName }));
    },

    minDateTime() {
      return moment().format('YYYY-MM-DDTHH:mm');
    },
  },

  data() {
    return {
      TestDurationStatus: false,
      scheduledStartTime: '',
      scheduledEndTime: '',
      editTestName: '',
      scheduleTestShow: true,
      generalStatus: false,
      overviewStatus: true,
      TestTimeStatus: false,
      evaStatus: false,
      advanceStatus: false,
      showTestNameButtons: false,
      testSummary: [],
      testSection: [],
      routetestId: window.atob(this.$route.query.id),
      routesearch: window.atob(this.$route.query.search),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      webProctorStatus: false,
      videoProctorStatus: false,
      pictureCaptureStatus: false,
      videoCaptureStatus: false,
      audioCaptureStatus: false,
      logsEnabledStatus: false,
      IsBehaviouralInterviewQuestionEnabledStatus: false,
      IsEvaluationEnabledStatus: false,
      IsManagerialReccomendationEnabledStatus: false,
      IsShowAnswerSheetStatus: false,
      advanceModalTitle: '',
      advanceModalStatus: false,
      advanceType: '',
      GetTimeZoneData: {
        object: {
          data: [],
        },
      },
      TimeZoneValue: "",
      AdvanceCandidateDetails: [],
      editMode: false,
      editTestSectionMode: false,

      postFieldName: '',
      postFieldType: '',
      postMandatoryStatus: false,
      postOptions: '',
      aptiShortenStatus: false,
      aptiDetailedStatus: false,
      psyShortenStatus: false,
      psyDetailedStatus: false,
      TestSectionsDetails: [],
      model: [],
      textPreferences: [],
      boolPreferences: [],
      dropPreferences: [],
      defaultTextPreferences: [],
      changedPreferences: [],
      defaultDropdownPreferences: [],
      changedDropdownPreferences: [],
      preferenceModalTitle: '',
      preferenceModalStatus: false,
      currentPreferenceValue: []
      // sectionNames:[]
    }
  },
  watch: {
    scheduledStartTime() {
      this.validateEndMax();
    },
    scheduledEndTime() {
      this.validateEndMax();
    },
  },
  async created() {
    await this.OverallData();
  },
  methods: {
    ...mapActions([
      "activetestdetail",
      "activetestsection",
      "ShowWarningMessage",
      "putTestDuration",
      "patchTestDuration",
      "getTestDuration",
      "putTestName",
      "getProctorSettings",
      "putProctorSettings",
      "getAdvanceSettings",
      "putAdvanceSettings",
      "getTimeZone",
      "getCandidateAdditionalDetails",
      "getReportSettingsDetails",
      "getTestSectionsDetails",
      "getLanguagePreferencesDetails",
      "getLanguageWeightageDetails",
      "getLanguageApiConditionDetails",
      "putCandidateAdditionalField",
      "deleteCandidateAdditionalField",
      "postCandidateAdditionalField",
      "putReportSettingsDetails",
      "putTestSectionsDetails",
      "deleteTestWeightageValue",
      "putLanguagePreferencesDetails"
    ]),
    ...mapMutations(["setLoading"]),
    superadmin,
    dateConvert: function (value) {
      return moment(value, "HH:mm:ss").format((moment(value, "HH:mm:ss").hour() === 0 ? "m [min]" : "h [hr] m [min]"));
    },
    async getTimeZones() {
      this.GetTimeZoneData = await this.getTimeZone({
        countryCode: this.user.accountLocation
      });
    },

    async postToggleCollapse() {
      this.postMandatoryStatus = !this.postMandatoryStatus;
    },

    async PostCandidateAdditionalField() {
      var object =
        [
          {
            "fieldName": this.postFieldName,
            "fieldType": this.postFieldType,
            "options": this.postOptions,
            "isMandatory": this.postMandatoryStatus,
            "srNo": 0,
            "fieldCategory": 0
          }
        ]

      var data = await this.postCandidateAdditionalField({
        testId: this.routetestId,
        createModel: object
      });

      if (data.status) {
        await this.GetCandidateDetailsData();
        this.postFieldName = '';
        this.postFieldType = '';
        this.postMandatoryStatus = false;
        this.postOptions = '';
      }
    },

    async ResetCandidateAdditionalField() {
      this.postFieldName = '';
      this.postFieldType = '';
      this.postMandatoryStatus = false;
      this.postOptions = '';
    },

    toggleIsMandatory(item) {
      item.isMandatory = !item.isMandatory;
    },
    editItem(item) {
      item.editMode = true;
      item.originalData = { ...item };
    },
    editTestSectionItem(item) {
      item.editTestSectionMode = true;
      item.originalTestSectionData = { ...item };
    },

    async saveTestSectionItem(item) {
      item.editTestSectionMode = false;

      await this.putTestSectionsDetails({
        testId: this.routetestId, //this.routetestId,
        sectionId: item.sectionId,
        minScore: item.minScore,
        maxScore: item.maxScore,
        weightageValue: item.weightageValue,
        isPsy: false,

      });
    },
    async saveItem(item) {
      item.editMode = false;

      await this.putCandidateAdditionalField({
        testId: this.routetestId,
        masterId: item.cadmid,
        fieldName: item.fieldName,
        fieldType: item.fieldType,
        options: item.options,
        isMandatory: item.isMandatory,
        fieldCategory: 1
      });
    },
    cancelEdit(item) {
      Object.assign(item, item.originalData);
      item.editMode = false;
    },
    cancelTestSectionEdit(item) {
      Object.assign(item, item.originalTestSectionData);
      item.editTestSectionMode = false;
    },
    async deleteItem(item) {
      var data = this.AdvanceCandidateDetails.filter(i => i.cadmid === item.cadmid);
      var apiData = await this.deleteCandidateAdditionalField({
        Id: data[0].cadmid
      });
      if (apiData.status) {
        this.AdvanceCandidateDetails = this.AdvanceCandidateDetails.filter(i => i.cadmid !== item.cadmid);
      }
    },
    async deleteTestSectionItem(item) {
      var apiData = await this.deleteTestWeightageValue({
        testId: this.routetestId,  //this.routetestId,
        sectionId: item.sectionId
      });
      if (apiData.status) {
        // this.AdvanceCandidateDetails = this.AdvanceCandidateDetails.filter(i => i.sectionId !== item.sectionId);
        await this.GetTestSectionsDetailsData();
      }
    },

    async GetProctorSettingData() {
      var data = await this.getProctorSettings({
        testId: this.routetestId
      });
      var proctorData = data.object.data;
      this.webProctorStatus = proctorData.webProctor;
      this.videoProctorStatus = proctorData.videoProctor;
      this.pictureCaptureStatus = proctorData.pictureCapture;
      this.videoCaptureStatus = proctorData.videoCapture;
      this.audioCaptureStatus = proctorData.audioCapture;
      this.logsEnabledStatus = proctorData.logsEnabled;
    },

    async PutProctorSettingsData() {
      await this.putProctorSettings({
        testId: this.routetestId,
        webProctor: this.webProctorStatus,
        videoProctor: this.videoProctorStatus,
        pictureCapture: this.pictureCaptureStatus,
        videoCapture: this.videoCaptureStatus,
        audioCapture: this.audioCaptureStatus,
        logsEnabled: this.logsEnabledStatus
      })
    },
    async PutAdvanceSettings() {
      var data = await this.putAdvanceSettings({
        testId: this.routetestId,
        preferenceName: this.advanceType,
        preferenceValue: this.advanceModalStatus
      })
      if (data) {
        await this.GetAdvanceSettings();
        window.$("#AdvanceValidation").modal("hide");
        this.advanceType = '';
        this.advanceModalStatus = false;
        this.advanceModalTitle = '';
      }
    },
    async ResetPutAdvanceSettings() {
      this.advanceType = '';
      this.advanceModalStatus = false;
      this.advanceModalTitle = '';
    },

    async GetCandidateDetailsData() {
      var data = await this.getCandidateAdditionalDetails({
        testId: this.routetestId
      });
      this.AdvanceCandidateDetails = data.object.data;
    },

    async GetAdvanceSettings() {
      var data = await this.getAdvanceSettings({
        testId: this.routetestId
      })
      var advanceData = data.object.data;
      this.IsBehaviouralInterviewQuestionEnabledStatus = advanceData.isBehaviouralInterviewQuestionEnabled;
      this.IsEvaluationEnabledStatus = advanceData.isEvaluationEnabled;
      this.IsManagerialReccomendationEnabledStatus = advanceData.isManagerialReccomendationEnabled;
      this.IsShowAnswerSheetStatus = advanceData.isShowAnswerSheet;

    },

    async GetReportSettingsData() {
      var data = await this.getReportSettingsDetails({
        TestId: this.routetestId
      });

      var ReportData = data.object.data;

      this.aptiShortenStatus = ReportData.aptiShorten;
      this.aptiDetailedStatus = ReportData.aptiDetailed;
      this.psyShortenStatus = ReportData.psyShorten;
      this.psyDetailedStatus = ReportData.psyDetailed;

    },
    async GetTestSectionsDetailsData() {
      var data = await this.getTestSectionsDetails({
        testId: this.routetestId // this.routetestId 
      });
      this.TestSectionsDetails = data.object.data;
    },

    async GetLanguagePreferencesData() {
      var data = await this.getLanguagePreferencesDetails({
        testId: this.routetestId
      });

      var LanguageData = data.object.data;

      await this.segregatePreferences(LanguageData);
    },
    async GetLanguageWeightageData() {
      var data = await this.getLanguageWeightageDetails({
        testId: this.routetestId
      });
      this.model = data.object.data.sections;
    },

    segregatePreferences(item) {
      item.forEach(preference => {
        if (preference.preferenceType === "text") {
          this.textPreferences.push(preference);
          this.defaultTextPreferences.push({ ...preference });
          this.changedPreferences.push(false);

        } else if (preference.preferenceType === "bool") {
          this.boolPreferences.push(preference);

        } else if (preference.preferenceType === "drop") {
          this.dropPreferences.push(preference);
          this.defaultDropdownPreferences.push({ ...preference });
          this.changedDropdownPreferences.push(false);
        }
      });
    },
    async GetLanguageApiConditionData() {
      await this.getLanguageApiConditionDetails({
        testId: this.routetestId
      });
    },

    markChanged(index) {
      this.changedPreferences[index] = this.textPreferences[index].preferenceValue !== this.defaultTextPreferences[index].preferenceValue;
    },
    dropChanged(index) {
      this.changedDropdownPreferences[index] =
        this.dropPreferences[index].preferenceValue !==
        this.defaultDropdownPreferences[index].preferenceValue;
    },

    async updatePreference(item) {
      // this.defaultTextPreferences[index].preferenceValue = this.textPreferences[index].preferenceValue;
      // this.changedPreferences[index] = false;
      var status;
      if (item.preferenceValue != '') {
        status = true;
      }
      else {
        status = false
      }
      var data = await this.putLanguagePreferencesDetails({
        testId: this.routetestId,
        preferenceName: item.preferenceName,
        preferenceLabel: item.preferenceLabel,
        preferenceType: item.preferenceType,
        preferenceValue: item.preferenceValue,
        isActive: status
      });

      if (data.status) {
        await this.resetEva();
        await this.GetLanguagePreferencesData();
      }
    },

    resetEva(){
        this.textPreferences = [];
        this.boolPreferences = [];
        this.dropPreferences = [];
        this.defaultTextPreferences = [];
        this.defaultDropdownPreferences = [];
        this.changedPreferences = [];
        this.changedDropdownPreferences = [];
    },
    togglePreference(value) {
      if (value.preferenceValue == false) {
        this.preferenceModalTitle = "Do you want to enable " + value.preferenceLabel + " ?";
        this.preferenceModalStatus = true;
      }
      else {
        this.preferenceModalTitle = "Do you want to disable " + value.preferenceLabel + " ?";
        this.preferenceModalStatus = false;
      }
      this.currentPreferenceValue = value;
      window.$("#PreferenceValidation").modal("show");
    },
    async updatePreferenceData() {
      var data = await this.putLanguagePreferencesDetails({
        testId: this.routetestId,
        preferenceName: this.currentPreferenceValue.preferenceName,
        preferenceLabel: this.currentPreferenceValue.preferenceLabel,
        preferenceType: this.currentPreferenceValue.preferenceType,
        preferenceValue: this.currentPreferenceValue.preferenceValue,
        isActive: this.preferenceModalStatus
      });

      if (data.status) {
        this.preferenceModalTitle = '';
        this.preferenceModalStatus = false;
        this.currentPreferenceValue = [];
        await this.resetEva();
        await this.GetLanguagePreferencesData();
        window.$("#PreferenceValidation").modal("hide");
      }
    },

    async ResetTogglePreference() {
      this.preferenceModalTitle = '';
      this.preferenceModalStatus = false;
    },
    async GetTestDurationData() {
      var data = await this.getTestDuration({
        TestId: this.routetestId
      });
      // var startTime = data.object.data.start;
      // var endTime = data.object.data.end;

      this.scheduledStartTime = data.object.data.start;
      this.scheduledEndTime = data.object.data.end;

      // const offsetMatch = this.user.timezone.match(/([+-]\d{2}):?(\d{2})?/);
      // const hours = parseInt(offsetMatch[1]);
      // const minutes = offsetMatch[2] ? parseInt(offsetMatch[2]) : 0;
      // const offset = hours * 60 + minutes;


      // this.scheduledStartTime = moment.utc(startTime).utcOffset(offset).format("YYYY-MM-DDTHH:mm");
      // this.scheduledEndTime = moment.utc(endTime).utcOffset(offset).format('YYYY-MM-DDTHH:mm');

      if (data.isActive == true) {
        this.TestDurationStatus = true;
      }
    },

    async TestDetails() {
      var testdetail = await this.activetestdetail({
        testId: this.routetestId
      });
      this.testSummary = testdetail.object.data
      this.editTestName = this.testSummary.testName;
    },

    async OverallData() {
      await this.TestDetails();
      var testsections = await this.activetestsection({
        testId: this.routetestId
      });
      this.testSection = testsections.object.data
    },

    async GeneralData() {
      this.editTestName = this.testSummary.testName;
    },


    async UpdateTestName() {
      if (this.editTestName == '') {
        this.editTestName = this.testSummary.testName;
        this.ShowWarningMessage("Test name can't be empty");
      }
      else {
        var data = await this.putTestName({
          testId: this.routetestId,
          testName: this.editTestName
        });
        if (data.status) {
          await this.TestDetails();
        }
      }
    },

    async PutReportSettingsDetails() {
      await this.putReportSettingsDetails({
        TestId: this.routetestId,
        aptiShorten: this.aptiShortenStatus,
        aptiDetailed: this.aptiDetailedStatus,
        psyShorten: this.psyShortenStatus,
        psyDetailed: this.psyDetailedStatus
      });
    },
    encode: function (item) {
      return window.btoa(item);
    },


    validateEndMax() {
      if (this.scheduledStartTime && this.scheduledEndTime) {
        if (new Date(this.scheduledEndTime) < new Date(this.scheduledStartTime)) {
          this.scheduleTestShow = false;
          return this.ShowWarningMessage("End Time can't be less than Start Time");
        }
        this.scheduleTestShow = true;
      }
      return false;
    },

    async changeTab(value) {
      await this.ResetTabs();
      switch (value) {
        case 'general':
          this.generalStatus = true;
          await this.resetEva();
          this.GeneralData();
          this.GetProctorSettingData();
          break;
        case 'overview':
          this.overviewStatus = true;
          await this.resetEva();
          break;
        case 'test-time':
          this.TestTimeStatus = true;
          await this.resetEva();
          if (this.user.accountLocation != this.user.countryCode) {
            this.getTimeZones();
          }
          this.GetTestDurationData();
          break;
        case 'eva':
          this.evaStatus = true;
          await this.resetEva();
          this.GetLanguagePreferencesData();
          this.GetLanguageWeightageData();
          this.GetLanguageApiConditionData();
          break;
        case 'advance':
          this.advanceStatus = true;
          await this.resetEva();
          this.GetAdvanceSettings();
          this.GetCandidateDetailsData();
          this.GetReportSettingsData();
          this.GetTestSectionsDetailsData();
          break;
        default:
          break;
      }
    },
    getWeightage(sectionName, parameterName) {
      const section = this.sectionNames.find(s => s.sectionName === sectionName);
      if (!section) return '';
      const param = section.languageParameters.find(p => p.parameterName === parameterName);
      return param ? param.weightageValue : 'NA';
    },


    async UpdateTestDurationData() {
      var timezone;
      var status = true;
      if (this.user.accountLocation != this.user.countryCode) {
        if (!this.TimeZoneValue) {
          status = false;
          return this.ShowWarningMessage("Select Timezone Data");
        }
        timezone = this.TimeZoneValue;
      }
      else {
        timezone = this.user.timezone;
      }

      if (status) {
        await this.putTestDuration({
          testId: this.routetestId,
          start: this.scheduledStartTime,
          end: this.scheduledEndTime,
          timeZone: timezone,
          isActive: true
        });

      }

    },

    ResetTabs() {
      this.generalStatus = false;
      this.overviewStatus = false;
      this.TestTimeStatus = false;
      this.evaStatus = false;
      this.advanceStatus = false;
    },
    async toggleCollapse() {
      if (this.TestDurationStatus == true) {
        window.$("#DurationStatus").modal("show");
      }
      else {
        this.TestDurationStatus = !this.TestDurationStatus;
      }
    },

    async toggleWebProctor() {
      this.webProctorStatus = !this.webProctorStatus;
      if (!this.webProctorStatus) {
        this.videoProctorStatus = false;
        this.pictureCaptureStatus = false;
        this.videoCaptureStatus = false;
        this.audioCaptureStatus = false;
        this.logsEnabledStatus = false;
      }
    },
    async toggleVideoProctor() {
      this.videoProctorStatus = !this.videoProctorStatus;
    },
    async togglepictureCapture() {
      this.pictureCaptureStatus = !this.pictureCaptureStatus;
    },
    async toggleVideoCapture() {
      this.videoCaptureStatus = !this.videoCaptureStatus;
    },
    async toggleAudioCapture() {
      this.audioCaptureStatus = !this.audioCaptureStatus;
    },
    async toggleLogsEnabled() {
      this.logsEnabledStatus = !this.logsEnabledStatus;
    },
    async toggleAptiShortenStatus() {
      this.aptiShortenStatus = !this.aptiShortenStatus;
    },
    async toggleAptiDetailedStatus() {
      this.aptiDetailedStatus = !this.aptiDetailedStatus;
    },
    async togglePsyShortenStatus() {
      this.psyShortenStatus = !this.psyShortenStatus;
    },
    async togglePsyDetailedStatus() {
      this.psyDetailedStatus = !this.psyDetailedStatus;
    },
    async toggleIsBehaviouralInterviewQuestionEnabled() {
      if (!this.IsBehaviouralInterviewQuestionEnabledStatus) {
        this.advanceModalTitle = "Do you want to enable behavioural interview questions?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable behavioural interview questions?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'BehaviouralInterviewQuestionEnabled';
      window.$("#AdvanceValidation").modal("show");
    },

    async toggleIsEvaluationEnabled() {
      if (!this.IsEvaluationEnabledStatus) {
        this.advanceModalTitle = "Do you want to enable manual evaluation?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable manual evaluation?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'EvaluationEnabled';
      window.$("#AdvanceValidation").modal("show");
    },

    async toggleIsManagerialReccomendationEnabled() {
      if (!this.IsManagerialReccomendationEnabledStatus) {
        this.advanceModalTitle = "Do you want to enable managerial reccomendation?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable managerial reccomendation?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'ManagerialRecommendationEnabled';
      window.$("#AdvanceValidation").modal("show");
    },

    async toggleIsShowAnswerSheet() {
      if (!this.IsShowAnswerSheetStatus) {
        this.advanceModalTitle = "Do you want to enable show answer sheet?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable show answer sheet?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'ShowAnswerSheet';
      window.$("#AdvanceValidation").modal("show");
    },

    async patchDuration() {
      await this.patchTestDuration({
        testId: this.routetestId,
        status: false
      });
      this.TestDurationStatus = !this.TestDurationStatus;
      window.$("#DurationStatus").modal("hide");

    },
    async resetData() {
      await this.GetTestDurationData();
    },
  },
};
</script>

<style scoped>
.accordion-button::after {
  display: none !important;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  background-color: #fff !important;
}

.toggle-button {
  width: 52px;
  min-width: 52px;
  height: 28px;
  background-color: #ccc;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.secondary-button1 {
  border: 2px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  font-weight: 600;
  border-radius: 21px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 13px;
}

.col-sm-12 .btn-primary {
  margin-left: 5%;
}

.toggle-button::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-button.active {
  background-color: #7b68ee;
}

.toggle-button.active::before {
  transform: translateX(100%);
}

.toggle-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.toggle-button.mandatorybutton:disabled {
  background-color: #c9c0ff;
  cursor: not-allowed;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
}

.table-header {
  text-align: center;
  margin-bottom: 10px;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
}

.select-container {
  position: relative;
  width: 100%;
}

.custom-select {
  width: 100%;
  border-radius: 5px;
  padding-left: 5%;
  height: 100%;
  min-height: 50px;
  appearance: none;
  padding-right: 30px;
}

.select-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>