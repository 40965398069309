import { decryptData } from '@/platformFeatures/security';
import router from "../router";
import { getAuthUserData} from "@/utils/auth";


export function getEncryptedData(key) {
    let secretKey = key;
    let encryptedData = localStorage.getItem("featuresData");
    if (encryptedData) {
        let decryptedData = decryptData(encryptedData, secretKey);
        // if (Array.isArray(decryptedData) && decryptedData.length > 0) {
        //     let data = decryptedData;
        //     return data;
        // }
        return decryptedData;
    }
}

// export function validatePlanFeatures(key, id, name) {
//     let featureData = getEncryptedData(key);
//     let userAuth = getAuthUserData(); 
//     if (featureData && userAuth.isEnterprise==false) {
//         if (!featureData.some(item => item.featureId === id && item.featureName === name)) {
//             return false;
//         }
//         else{
//             return true;
//         }
//     }
//     else{
//         return true;
//     }
// }
export function validatePlanFeatures(key, moduleName, id, name) {
    let featureData = getEncryptedData(key);
    let userAuth = getAuthUserData(); 
    if (featureData && userAuth.isEnterprise == false) {
        let module = featureData.modules.find(module => module.moduleName === moduleName);
        if (module) {
            if (module.features.some(item => item.featureId === id && item.featureName === name)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false; 
        }
    } else {
        return true;
    }
}

export function validateTeamMembers(key, moduleName) {
    let featureData = getEncryptedData(key);
    let userAuth = getAuthUserData(); 
    if (featureData && userAuth.isEnterprise == false) {
        let module = featureData.modules.find(module => module.moduleName === moduleName);
        if (module) {
            let feature = module.features.find(item => item.featureId === 26);
            return feature.value;
        } else {
            return 0; 
        }
    } else {
        return 0;
    }
}

export function AccessPlanFeatures(key, id, name) {
    let featureData = getEncryptedData(key);
    let userAuth = getAuthUserData();
    if (featureData && userAuth.isEnterprise==false) {
        if (!featureData.some(item => item.featureId === id && item.featureName === name)) {
            router.push("/billing-plan");
        }
        else{
           return true;
        }
    }
    return true;
}
