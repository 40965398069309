<template>
  <div class="page-content">
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown" v-if="this.testSummary.testName">
                <router-link
                :to="{
                  name: 'ActiveTests',
                  query: {
                    id: this.routesearch
                      ? encode(this.testSummary.testName)
                      : '',
                    offset: this.routeoffset ? encode(this.routeoffset) : '',
                    current: this.routecurrent ? encode(this.routecurrent) : '',
                  },
                }"
                class="p-0"
                type="button"
              >
                <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <span v-if="this.editStatus==false">
          <h5 class="mb-1 font-size-MV" v-if="this.screenWidth <= 1441 &&
            this.screenWidth > 767 
            ">
           
           <span
      class="mb-2 mb-md-0"
      style="font-weight: 500; font-size: 18px;  width: 200px;"
    >          {{
              tname.substring(
                0,
                tname.lastIndexOf(tname.substring(40))
              ) + "..."
            }}
</span> 

          </h5>
          <h5 class="mb-1 font-size-MV" v-else-if="this.screenWidth <= 767 ">
          

            <span
      class="mb-2 mb-md-0"
      style="font-weight: 500; font-size: 18px;  width: 200px;"
    >          {{
              tname.substring(
                0,
                tname.lastIndexOf(tname.substring(30))
              ) + "..."
            }}  
        </span> 
          </h5>
          <h4 class="mb-1 font-size-MV" v-else>

            <span
      class="mb-2 mb-md-0"
      style="font-weight: 500; font-size: 18px; width: 200px;"
    >
      {{ tname }}
    </span>         
          </h4>
        </span>
          <!-- <span
      class="mb-2 mb-md-0"
      :contenteditable="editStatus"
      @input="update"
      :class="{ bordered: editStatus }"
      style="font-weight: 500; font-size: 18px; width: 200px;"
      ref="editTestName"
    >
      {{ tname }}
    </span>  -->
          <!-- <h6 class="text-muted" v-if="this.candidateList.length != 0">
            Candidates ({{ filteredCandidates }} out of {{ totalCandidates }} candidates)
          </h6> -->
          <h4 v-if="editStatus == true" >
            <span  class="row">
              <span class="col-12 col-sm-11 col-md-11 col-lg-11">
              <input type="text" v-model="this.limitTestName" @input="handleInput" style="width: 100%;"> 
              </span>
              <span class="col-12 col-sm-1 col-md-1 col-lg-1">
              <span @click="editTestNames()">  <i class="fa-regular fa-circle-check  mx-4" style="color: #7c68ee; cursor: pointer;"></i></span>
              </span>
            </span>
          </h4>
          <h6 class="text-muted">
Test Id: {{ this.routetestId }}
          </h6>
        </div>
      </div>
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
          <div class="Group_top_three_dot">
            <div class="d-flex align-items-center">
              <button
            type="button"
            class="btn btn-primary2 btn-icon-text mx-4 pl-4"
           @click="toggleEdit"
           v-if="this.user.isEnterprise==false"
            >Edit 
          <i class="fa-solid fa-pen-to-square fa-lg" style="color: #7b68ee;"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary btn-icon-text pl-4"
            @click="invite"
          >
            Invite
          </button>
            </div>
         
          </div>
        </div>
  
    </div>

    <section>
      <div class="row">
        <h4 class="my-3 Overview-color">Overview</h4>
        <!-- Added by Pmaps team -->
        <div v-if="routetestId === '3882'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's ability to resolve
            customer queries while ensuring them a pleasant experience.
          </p>
          <p class="my-3 Overview-color"><b>Test Use:</b> Pre-hiring.</p>
          <p class="my-3 Overview-color">
            <b>Used for:</b> Customer Service Executives.
          </p>
        </div>
        <div v-if="routetestId === '3824'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's managerial capability
            aimed at organisational development.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring.
          </p>
          <p class="my-3 Overview-color"><b>Used for:</b> Managers.</p>
        </div>
        <div v-if="routetestId === '3845'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Identifies a candidate's capability for sales
            channel management and relationship-building with customers.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring.
          </p>
          <p class="my-3 Overview-color">
            <b>Used for:</b> Executives less than 3 years of experience.
          </p>
        </div>
        <div v-if="routetestId === '3846'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's propensity for providing
            consumers with high-quality service.
          </p>
          <p class="my-3 Overview-color"><b>Test Use:</b> Pre-Hiring.</p>
          <p class="my-3 Overview-color"><b>Used for:</b> Entry Level.</p>
        </div>
        <div v-if="routetestId === '3851'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's aptitude and skills
            required to fulfill their job roles.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring, Training.
          </p>
          <p class="my-3 Overview-color"><b>Used for:</b> Executives.</p>
        </div>
        <div v-if="routetestId === '3858'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Examines a candidate's likelihood of succeeding
            in a sales position as an insurance agent.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring.
          </p>
          <p class="my-3 Overview-color">
            <b>Used for:</b> Executives less than 3 years of experience.
          </p>
        </div>
        <div v-if="routetestId === '3879'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's knowledge of programming
            languages, syntax and logic building.
          </p>
          <p class="my-3 Overview-color"><b>Test Use:</b> Pre-hiring.</p>
          <p class="my-3 Overview-color"><b>Used for:</b> Entry Level.</p>
        </div>
        <div v-if="routetestId === '3880'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Aims to identify high potential employees who
            would drive the organization's success.
          </p>
          <p class="my-3 Overview-color"><b>Test Use:</b> Post-Hiring .</p>
          <p class="my-3 Overview-color">
            <b>Used for:</b> Team Leaders, Managers.
          </p>
        </div>
        <div v-if="routetestId === '3881'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses an candidate's ability to identify and
            convince sales prospects for business growth.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring, Training.
          </p>
          <p class="my-3 Overview-color">
            <b>Used for:</b> Executives less than 3 years of experience.
          </p>
        </div>
        <div v-if="routetestId === '3883'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses the candidate's voice and accent
            required to effectively interact with the customers.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring, Training.
          </p>
          <p class="my-3 Overview-color"><b>Used for:</b> Entry Level.</p>
        </div>
        <div v-if="routetestId === '3859'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's managerial capability
            aimed at organisational development.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring.
          </p>
          <p class="my-3 Overview-color"><b>Used for:</b> Managers.</p>
        </div>
        <div v-if="routetestId === '3850'">
          <p class="my-3 Overview-color">
            <b>Definition:</b> Assesses a candidate's aptitude and skills
            required to fulfill their job roles.
          </p>
          <p class="my-3 Overview-color">
            <b>Test Use:</b> Pre-Hiring and Post-Hiring, Training.
          </p>
          <p class="my-3 Overview-color">
            <b>Used for:</b> Mid level and Senior Level.
          </p>
        </div>
        <!-- Added by Pmaps team End -->
        <div class="col-lg-4 col-md-4">
          <div class="card shadow-none p-0 mb-3 pb-4">
            <div class="card-header card-header-Active-Invite-kj">
              <h5>Test Summary</h5>
            </div>
            <div class="card-body">
              <div class="active-list-invite-kj">
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span
                    ><img
                      src="../../public/dashboard-assets/images/M2/time-icon.png"
                      class="icon-img-kj-active"
                      alt=""
                    />Test Time
                  </span>
                  <!-- <span>{{ this.testTime }}min</span> -->
                  <span>{{ dateConvert(this.testTime) }}</span>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center mb-3"
                >
                  <span
                    ><img
                      src="../../public/dashboard-assets/images/M2/user.png"
                      class="icon-img-kj-active"
                      alt=""
                    />Candidates
                  </span>
                  {{ this.testSummary.completed }}/{{
                    this.testSummary.scheduled
                  }}
                </div>
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span
                    ><img
                      src="../../public/dashboard-assets/images/M2/date-time-icon.png"
                      class="icon-img-kj-active"
                      alt=""
                    />Start Date
                  </span>
                  <span v-if="this.ConvertedStartTime != null">{{
                    this.ConvertedStartTime
                  }}</span>
                  <span v-else>NaN</span>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center mb-3"
                >
                  <span
                    ><img
                      src="../../public/dashboard-assets/images/M2/date-time-icon.png"
                      class="icon-img-kj-active"
                      alt=""
                    />End Date
                  </span>
                  <span v-if="this.ConvertedEndTime != null">{{
                    this.ConvertedEndTime
                  }}</span>
                  <span v-else>NaN</span>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center mb-3"
                >
                  <span
                    ><img
                      src="../../public/dashboard-assets/images/M2/date-time-icon.png"
                      class="icon-img-kj-active"
                      alt=""
                    />Cooling Period
                  </span>
                  <span v-if="CheckSettingDays != null">
                    {{ CheckSettingDays }} Days
                  </span>
                  <span v-else>NaN</span>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="row">
            <div class="col-6 col-lg-3 me-0 pe-0">
              <div>
                <table class="table">
                  <thead class="table-header-bg-active-kj">
                    <tr>
                      <th class="text-dark">Test Sections</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr v-for="item in this.testSection" :key="item">
                      <td class="Overview-color">{{ item.sectionName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-6 col-lg-9 ms-0 ps-0">
              <div class="table-responsive">
                <table class="table bg-white">
                  <thead class="table-header-bg-active-kj">
                    <tr>
                      <th class="text-dark">No. of Questions</th>
                      <th class="text-dark">Absolute Benchmark(%)</th>
                      <th class="text-dark">Weightage</th>
                      <th class="text-dark">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in this.testSection" :key="item">
                      <td class="Overview-color">{{ item.noOfQuestion }}</td>
                      <td class="Overview-color">{{ item.passingScore }}</td>
                      <td
                        class="Overview-color"
                        v-if="item.weightageValue != null"
                      >
                        {{ item.weightageValue }}
                      </td>
                      <td class="Overview-color" v-else>NaN</td>
                      <td class="Overview-color">
                        {{ formattedTime(item.sectionTime) }}
                        <!-- {{ item.sectionTime.split(":", 2)[1] }}min -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Commented by Pmaps team -->
      <!-- <div class="row">
        <h4 class="my-3 Overview-color">Advanced Options</h4>
        <div class="col-md-12">
          <div class="accordion-item border-radius-kj">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div>
                  <h4>
                    Anti-Cheating Setting
                    <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                  </h4>
                  <p class="accordion-button-p-kj">
                    Detect cheaters and people using unfair advantages in
                    video-games
                  </p>
                </div>
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#FaqAccordion">
              <div class="accordion-body p-0 managementPartner-Acc-p-kj">
                <div class="px-3">
                  <div class="my-3">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" disabled type="radio" name="inlineRadioOptions" id="inlineRadio1"
                        value="option1" :checked="this.proctorSettings.webProctor" />
                      <label class="form-check-label" for="inlineRadio1">Web Proctoring</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" disabled type="radio" name="inlineRadioOptions" id="inlineRadio2"
                        value="option2" :checked="this.proctorSettings.videoProctor" />
                      <label class="form-check-label" for="inlineRadio2">Video Proctoring</label>
                    </div>
                  </div>
                  <div class="d-flex mb-3">
                    <div class="form-group">
                      <label class="mx-2">Audio Capture</label>
                      <input type="checkbox" disabled hidden="" id="Can_Access_Client0" class="switchInput"
                        :checked="this.proctorSettings.audioCapture" />
                      <label class="switch" for="Can_Access_Client0"></label>
                    </div>
                    <div class="form-group">
                      <label class="mx-2">Video Capture</label>
                      <input type="checkbox" disabled hidden="" id="Can_Access_Client1" class="switchInput"
                        :checked="this.proctorSettings.videoCapture" />
                      <label class="switch" for="Can_Access_Client1"></label>
                    </div>
                    <div class="form-group">
                      <label class="mx-2">Enable logs</label>
                      <input type="checkbox" disabled hidden="" id="Can_Access_Client2" class="switchInput"
                        :checked="this.proctorSettings.logsEnabled" />
                      <label class="switch" for="Can_Access_Client2"></label>
                    </div>
                    <div class="form-group">
                      <label class="mx-2">Capture Picture</label>
                      <input type="checkbox" disabled hidden="" id="Can_Access_Client3" class="switchInput"
                        :checked="this.proctorSettings.pictureCapture" />
                      <label class="switch" for="Can_Access_Client3"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-radius-kj">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapseOne">
                <div>
                  <h4>
                    General Setting
                    <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                  </h4>
                  <p class="accordion-button-p-kj">
                    Real time period and geographical location or a fictional
                    world and unfamiliar time period
                  </p>
                </div>
              </button>
            </h2>
            <div id="collapsetwo" class="accordion-collapse collapse" aria-labelledby="headingOne"
              data-bs-parent="#FaqAccordion">
              <div class="accordion-body p-0 managementPartner-Acc-p-kj">
                <div class="px-3">
                  <div class="my-3"></div>
                  <div class="d-flex mb-3">
                    <div class="form-group">
                      <label class="mx-2">Show Answer Sheet</label>
                      <input type="checkbox" disabled hidden="" id="Can_Access_Client0" class="switchInput"
                        :checked="!this.generalSettings.isShowAnswerSheet" />
                      <label class="switch" for="Can_Access_Client0"></label>
                    </div>
                    <div class="form-group">
                      <label class="mx-2">Evaluation</label>
                      <input type="checkbox" disabled hidden="" id="Can_Access_Client1" class="switchInput"
                        :checked="this.generalSettings.isEvaluationEnabled" />
                      <label class="switch" for="Can_Access_Client1"></label>
                    </div>
                    <div class="form-group">
                      <label class="mx-2">Aptitude Report Format</label>
                      <input type="checkbox" hidden="" id="Can_Access_Client2" class="switchInput">
                      <label class="switch" for="Can_Access_Client2"></label>
                    </div>
                    <div class="form-group">
                      <label class="mx-2">Psycometric Report Format</label>
                      <input type="checkbox" hidden="" id="Can_Access_Client3" class="switchInput">
                      <label class="switch" for="Can_Access_Client3"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Commented by Pmaps team End -->
      <!------------------ Schedule Test --------------------------------------------------->
      <!-- <div v-if="superadmin()" class="accordion-item border-radius-kj">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button text-dark accordion-color-kj"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#ScheduleCollapse"
            aria-expanded="true"
            aria-controls="ScheduleCollapse"
          >
            <div>
              <h4>
                Schedule Test
                <img
                  src="../../public/dashboard-assets/images/M2/Actions.png"
                  alt=""
                />
              </h4>
              <p class="accordion-button-p-kj">
                Schedule test as per your requirements
              </p>
            </div>
          </button>
        </h2>

        <div
          id="ScheduleCollapse"
          class="accordion-collapse collapse"
          aria-labelledby="ScheduleCollapse"
          data-bs-parent="#FaqAccordion"
        >
          <div class="accordion-body p-0 managementPartner-Acc-p-kj">
            <div class="tab-content clearfix">
              <div class="tab-pane active">
                <form class="my-4">
                  <div class="form-group">
                    <label class="mx-2">Status</label>
                    <input type="checkbox" hidden id="ScheduleToggle" class="switchInput"
                      :checked="ConvertedStartTime == null ? scheduleActive : true" @click="toggleTestDuration" />
                    <label class="switch" for="ScheduleToggle"></label>
                  </div>
                  <div class="row mt-5">
                    <div class="col-xl-5 col-lg-5 col-md-6">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <input type="datetime-local" class="form-control" id="start-time" :min="RequiredCurrentDateTime"
                            v-model="scheduledStartTime" :disabled="TestDurationStatus ? false : true"
                            :close-on-select="ConvertedStartTime == null" @change="isBeforeMinDateTime()" />
                          <span class="input-name">
                            Start Time
                            <span class="text-danger">*</span>
                          </span>
                        </label>
                      </div>
                      <span v-if="isBeforeMinDateTime()" style="color: red;">Start time cannot be less than current
                        time</span>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-6">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <input type="datetime-local" class="form-control" id="end-time" :min="RequiredCurrentDateTime"
                            v-model="scheduledEndTime" :disabled="TestDurationStatus ? false : true"
                            :close-on-select="ConvertedEndTime == null" />
                          <span class="input-name">
                            End Time
                            <span class="text-danger">*</span>
                          </span>
                        </label>
                        <p v-if="(validate() && ConvertedEndTime == null && !TestDurationStatus) || (validateUpdate() && ConvertedEndTime != null)"
                          style="color: red;">Start Time can't be greater than End Time</p>
                      </div>
                    </div>
                    <div class="my-3 test_instance_button">
                      <button class="btn btn-primary" type="button" @click="resetData()"
                        :disabled="TestDurationStatus == false">
                        Reset
                      </button>
                      <button class="mx-4 btn btn-primary" type="button"
                        @click="ConvertedStartTime ? updateData() : sendData()"
                        :disabled="validate() || CheckNull() || isBeforeMinDateTime()">
                        {{ ConvertedStartTime ? 'Update' : 'Save' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> -->


      <!-------------------------------------------------------------------------------------->


      <!------------------ Cooling Period --------------------------------------------------->
      

    
      <!-- <div v-if="superadmin()" class="accordion-item border-radius-kj">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button text-dark accordion-color-kj" role="button" data-bs-toggle="collapse"
            data-bs-target="#CoolingPeriodCollapse" aria-expanded="true" aria-controls="CoolingPeriodCollapse">
            <div>
              <h4>
                Cooling Period
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h4>
              <p class="accordion-button-p-kj">
                Add time interval between Candidates' first attempt and reattempt
              </p>
            </div>
          </button>
        </h2>
        <div id="CoolingPeriodCollapse" class="collapse" aria-labelledby="headingOne" data-bs-parent="#FaqAccordion">
          <div class="accordion-body p-0 managementPartner-Acc-p-kj">
            <div class="tab-content clearfix">
              <div class="tab-pane active">
                <form class="my-4">
                  <div class="form-group">
                    <label class="mx-2">Status</label>
                    <input type="checkbox" hidden="" id="CoolingPeriodStatus" class="switchInput"
                      :checked="this.CheckSettingDays" />
                    <label class="switch" for="CoolingPeriodStatus" @click="toggleCoolingPeriodDuration"></label>
                  </div>

                  <div class="row mt-5  collapse" id="CoolingPeriodCollapse">
                    <div class="col-xl-5 col-lg-5 col-md-6">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <select v-model="durationBlock" class="form-select input_area select_ca" id="durationOption"
                            :disabled="CoolingPeriodDurationStatus == false">
                            <option disabled :selected="false" v-bind:value="CheckSettingDays" v-if="CheckSettingDays <= 0" >
                      Select Days</option>
                            <option disabled :selected="false" v-bind:value="CheckSettingDays"
                              v-if="CheckSettingDays > 0">
                              Current Selected {{ CheckSettingDays }} Days</option>
                            <option v-for="item in durationList.object.data" :key="item" :value="item.id">
                              {{ item.name }}
                            </option>
                            <option value='-1' selected>+ Custom Days</option>
                          </select>

                          <span class="input-name">
                            Select Cooling Period Duration
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-6">
                      <div class="form-group mb-4">
                        <label class="form-outline">
                          <input type="number" id="durationOption" class="form-control" v-model="CustomDays"
                            @keydown="preventCoolingPeriodCustomNumbers" placeholder="Add Custom Days"
                            v-if="durationBlock == -1" :disabled="CoolingPeriodDurationStatus == false" />
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-6">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <Multiselect v-model="verificationBlock" mode="tags" :close-on-select="false" :options="{
                            EmailAddress: 'EmailAddress',
                            PAN: 'PAN',
                            Aadhaar: 'Aadhaar'
                          }" :disabled="CoolingPeriodDurationStatus == false" v-if="CheckSettingDays <= 0">
                          </Multiselect>

                          <Multiselect v-model="CheckSettingValidationType" mode="tags" :close-on-select="false"
                            :options="options" v-if="CheckSettingDays > 0"
                            :disabled="CoolingPeriodDurationStatus == false">
                          </Multiselect>
                          <span class="input-name">
                            Preferences of Cooling Period
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="my-5 test_instance_button">
                    <button class="btn btn-primary" type="button" @click="coolingPeriodResetData()"
                      :disabled="CoolingPeriodDurationStatus == false">
                      Reset
                    </button>
                    <button class="mx-4 btn btn-primary" type="button"
                      @click="CheckSettingDays ? UpdateCoolingPeriodData() : sendCoolingPeriodData()"
                      :disabled="(CheckSettingDays ? (CheckSettingValidationType == false || durationBlock == false || (durationBlock == -1 && (CustomDays == null || CustomDays <= 0))) : (verificationBlock == false || durationBlock == false || (durationBlock == -1 && (CustomDays == null || CustomDays <= 0))))">
                      {{ CheckSettingDays ? 'Update' : 'Save' }}
                    </button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!---------------------------------------------------------------------------------------------->
      <!------------------ Test Weightage ------------------------------------------------------------>
      <!-- <div class="accordion-item border-radius-kj">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
            data-bs-target="#TestWeightageCollapse" aria-expanded="true" aria-controls="TestWeightageCollapse">
            <div>
              <h4>
                Test Weightage
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h4>
              <p class="accordion-button-p-kj">
                The value or importance of something when compared with another thing
              </p>
            </div>
          </button>
        </h2>
        <div id="TestWeightageCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne"
          data-bs-parent="#FaqAccordion">
          <div class="accordion-body p-0 managementPartner-Acc-p-kj">
            <div class="mx-3 my-3">
              <span type="button" class="btn" data-bs-toggle="modal" data-bs-target="#AddTestWeightageModal">
                <i class="fa-solid fa-circle-plus fa-lg" style="color: #7870f1;"></i>
                <div class="form-check-inline mx-2">
                  <label class="form-check-label" for="inlineRadio1">
                    <p style="color: #7870f1;">Add Test Weightage</p>
                  </label>
                </div>
              </span>


              <div class="form-check mx-7 float-end  ">
                <label class="input-hero">
                  <input type="date" class="form-control" id="start-time" :min="minDateTime" />
                  <span class="input-name">
                    Start Date
                    <span class="text-danger">*</span></span>
                </label>
              </div>


            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card p-0 ">
                <div class="table-responsive">
                  <table class="table">
                    <thead style="background-color: #F1F0FF;">
                      <tr>
                        <th>Section Name</th>
                        <th>Section Weightage</th>
                        <th>Min Passing Score</th>
                        <th>Max Passing Score</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in TestWeightageData" :key="item">
                        <td v-if="item.sectionName != null">
                          {{ item.sectionName }}

                        </td>
                        <td v-if="item.weightageValue != null">
                          {{ item.weightageValue }}
                        </td>
                        <td v-if="item.weightageValue != null">{{ item.minScore }}</td>
                        <td v-if="item.weightageValue != null">{{ item.maxScore }}</td>
                        <td v-if="item.weightageValue != null">
                          <div class="Group_top_three_dot">
                            <div class="dropdown">
                              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa-solid fa-ellipsis"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>

                                  <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                    data-bs-target="#UpdateTestWeightageModal"
                                    @click="SelectedSectionData(item.sectionName, item.weightageValue, item.minScore, item.maxScore)">
                                    Edit Section
                                  </button>
                                </li>
                                <li>
                                  <button type="button" class="btn w-100 text-start">
                                    Delete Section
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>

                        </td>
                      </tr>
                      <tr v-for="item in TestWeightageData" :key="item">

                        <td v-if="item.sectionName == null && (totalWeightage < 1 || totalWeightage > 1)"
                          style="background-color: #e966668f;">
                          Total
                        </td>
                        <td v-else-if="item.sectionName == null">
                          Total
                        </td>
                        <td v-if="item.weightageValue == null && (totalWeightage < 1 || totalWeightage > 1)"
                          style="background-color: #e966668f;">
                          {{ totalWeightage }}
                        </td>
                        <td v-else-if="item.weightageValue == null">
                          {{ totalWeightage }}
                        </td>
                        <td v-if="item.weightageValue == null && (totalWeightage < 1 || totalWeightage > 1)"
                          style="background-color: #e966668f;">{{ item.minScore }}</td>
                        <td v-else-if="item.weightageValue == null">{{ item.minScore }}</td>

                        <td v-if="item.weightageValue == null && (totalWeightage < 1 || totalWeightage > 1)"
                          style="background-color: #e966668f;">{{ item.maxScore }}</td>
                        <td v-else-if="item.weightageValue == null">{{ item.maxScore }}</td>
                        <td v-if="item.weightageValue == null && (totalWeightage < 1 || totalWeightage > 1)"
                          style="background-color: #e966668f;">
                          <div class="Group_top_three_dot">
                            <div class="dropdown">
                              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa-solid fa-ellipsis"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>

                                  <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                    data-bs-target="#UpdateTestWeightageModal"
                                    @click="SelectedSectionData(item.sectionName, item.weightageValue, item.minScore, item.maxScore)">
                                    Edit Section
                                  </button>
                                </li>
                                <li>
                                  <button type="button" class="btn w-100 text-start">
                                    Delete Section
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>

                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade CreatePartnerModal_hp" id="AddTestWeightageModal" tabindex="-1" data-bs-keyboard="false"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">


          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto">
                New Section Weightage

              </h4>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"></button>
            </div>
            <div class="modal-body">


              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">
                  <select name="" class="form-select input_area select_ca" id="" placeholder="Select Section">

                    <option value='' selected disabled>Select Session</option>
                    <option v-for="item in TestWeightageData" :key="item" :value="item.id">
                      {{ item.sectionName }}</option>
                  </select>
                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">
                  <input type="number" class="form-control" id="" placeholder="WeightageValue"
                    @keydown="preventWeightageNumbers">

                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <input type="number" class="form-control" id="" placeholder="Minimum Passing Score"
                    @keydown="preventNegativeNumbers">



                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <input type="number" class="form-control" id="" placeholder="Maximum Passing Score"
                    @keydown="preventNegativeNumbers">

                </label>
              </div>
              <div class="mb-3 row">
                <label class="col-5 col-form-label">Has Section Weightage?</label>
                <div class=" col-7 ">
                  <div class="form-group my-2">
                    <input type="checkbox" disabled hidden="" id="Can_Section Weightage" class="switchInput" />
                    <label class="switch" for="Can_Access_Client0"></label>
                  </div>
                </div>
              </div>
              <div class="test_instance_button float-end">
                <button class=" btn btn-primary" type="button">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="modal fade CreatePartnerModal_hp" id="UpdateTestWeightageModal" tabindex="-1" data-bs-keyboard="false"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">


          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto">
                Edit Section

              </h4>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"></button>
            </div>
            <div class="modal-body">


              <div class="form-group mb-4 my-2 " v-if="SectionName != null">
                <label class="input-hero form-control">
                  {{ SectionName }}
                </label>
              </div>

              <div class="form-group mb-4 my-2 " v-else>
                <label class="  input-hero form-control">
                  Total
                </label>
              </div>
              <div class="form-group mb-4 my-2 " v-if="SectionWeightageValue != null">
                <label class="input-hero">
                  <input type="number" class="form-control" id="" placeholder="WeightageValue"
                    v-model="this.SectionWeightageValue" @keydown="preventWeightageNumbers">
                </label>
              </div>
              <div class="form-group mb-4 my-2 " v-else>
                <label class="input-hero form-control">
                  {{ totalWeightage }}
                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <input type="number" class="form-control" id="" placeholder="Minimum Passing Score"
                    v-model="SectionMinScore" @keydown="preventNegativeNumbers">

                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <input type="number" class="form-control" id="" placeholder="Maximum Passing Score"
                    v-model="SectionMaxScore" @keydown="preventNegativeNumbers">

                </label>
              </div>
              <div class="mb-3 row">
                <label class="col-5 col-form-label">Has Section Weightage?</label>
                <div class=" col-7 ">
                  <div class="form-group my-2">
                    <input type="checkbox" disabled hidden="" id="Can_Section Weightage" class="switchInput" />
                    <label class="switch" for="Can_Access_Client0"></label>
                  </div>
                </div>
              </div>
              <div class="test_instance_button float-end">
                <button class=" btn btn-primary" type="button">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->


      <!----------------------------------------------------------------------------------------------->


      <!------------------ eVA Settings --------------------------------------------------------------->
      <!-- <div class="accordion-item border-radius-kj">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
            data-bs-target="#eVACollapse" aria-expanded="true" aria-controls="eVACollapse">
            <div>
              <h4>
                eVA Settings
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h4>
              <p class="accordion-button-p-kj">
                eVA is a financial metric based on residual wealth
              </p>
            </div>
          </button>
        </h2>
        <div id="eVACollapse" class="accordion-collapse collapse" aria-labelledby="headingOne"
          data-bs-parent="#FaqAccordion">
          <div class="accordion-body p-0 managementPartner-Acc-p-kj">
            <div class="mx-3">
              <div class="my-3" data-bs-toggle="modal" data-bs-target="#AddCandidateRegistrationFieldModal">
                <i class="fa-solid fa-circle-plus fa-lg" style="color: #7870f1;"></i>
                <div class="form-check-inline mx-2 my-2">
                  <label class="form-check-label" for="inlineRadio1">
                    <p style="color: #7870f1;">
                      Generate eVA Weightage</p>
                  </label>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card p-0 ">
                <div class="table-responsive">
                  <table class="table">
                    <thead style="background-color: #F1F0FF;">
                      <tr>
                        <th>Section Name</th>
                        <th v-for="(parameter, index) in LanguageWeightageDetailsList" :key="index">
                          <template v-if="index === getFirstIndex(parameter.parameterName)">
                            {{ parameter.parameterName }}
                          </template>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(section, index) in sections" :key="index">
                        <td>{{ section.sectionName }}</td>
                        <td v-for="(parameter, index) in uniqueParameters" :key="index">
                          <template v-if="getParameterWeightage(section, parameter)">
                            {{ getParameterWeightage(section, parameter) }}
                          </template>
                          <template v-else>0.0</template>
                          <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fa-solid fa-ellipsis-vertical px-4" style="color: #738fbf;"></i>
                          </button>

                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>

                              <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                data-bs-target="#EvaWeightageModal"
                                @click="SelectedEvaWeightageData(getParameterWeightage(section, parameter), parameter, section.sectionName)">
                                Edit Section
                              </button>
                            </li>
                            <li>
                              <button type="button" class="btn w-100 text-start">
                                Delete Section
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                  <div class="modal fade CreatePartnerModal_hp" id="EvaWeightageModal" tabindex="-1"
                    data-bs-keyboard="false" aria-hidden="true" data-bs-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">


                      <div class="modal-content ">
                        <div class="modal-header">
                          <h4 class="modal-title text-centerm-auto">
                            Eva Weightage

                          </h4>
                          <button type="button" class="btn-close" id="close" data-bs-dismiss="modal"
                            aria-label="btn-close"></button>
                        </div>
                        <div class="modal-body">


                          <div class="form-group mb-4 my-2 ">
                            <label class="input-hero">
                              <input type="text" class="form-control" id="" placeholder="Parameter Name"
                                v-model="this.paraName" disabled />
                              <span class="input-name">
                                Parameter Name
                                <span class="text-danger">*</span></span>
                            </label>
                          </div>
                          <div class="form-group mb-4 my-2 ">

                            <label class="input-hero">
                              <input type="text" class="form-control" id="" placeholder="Section Name"
                                v-model="this.sectName" disabled />

                              <span class="input-name">
                                Section Name
                                <span class="text-danger">*</span></span>
                            </label>
                          </div>
                          <div class="form-group mb-4 my-2 ">
                            <label class="input-hero">
                              <span>
                                <input type="text" class="form-control" id="" placeholder="Selected Weightage"
                                  v-model="this.selectedWeightage" />
                              </span>
                              <span class="input-name">
                                Weightage
                                <span class="text-danger">*</span></span>
                            </label>
                          </div>


                          <div class="test_instance_button float-end">
                            <button class=" btn btn-primary" type="button">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!----------------------------------------------------------------------------------------------->


      <!------------------ Candidate Registration --------------------------------------------------->
      <!-- <div class="accordion-item border-radius-kj">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
            data-bs-target="#CandidateRegistrationCollapse" aria-expanded="true"
            aria-controls="CandidateRegistrationCollapse">
            <div>
              <h4>
                Candidate Registration Field
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h4>
              <p class="accordion-button-p-kj">
                Candidate's Personal Information including Type of Recruitment
              </p>
            </div>
          </button>
        </h2>
        <div id="CandidateRegistrationCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne"
          data-bs-parent="#FaqAccordion">
          <div class="accordion-body p-0 managementPartner-Acc-p-kj">
            <div class="mx-3">
              <div class="my-3" data-bs-toggle="modal" data-bs-target="#AddCandidateRegistrationFieldModal">
                <i class="fa-solid fa-circle-plus fa-lg" style="color: #7870f1;"></i>
                <div class="form-check-inline mx-2 my-2">
                  <label class="form-check-label" for="inlineRadio1">
                    <p style="color: #7870f1;">
                      Add Candidate Registration Field</p>
                  </label>

                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="card p-0 ">
                <div class="table-responsive">
                  <table class="table">

                    <thead style="background-color: #F1F0FF;">
                      <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Mandatory</th>
                        <th>Options</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr v-for="item in CandidateAdditionalDetailsList" :key="item">

                        <td style="max-width:290px; overflow: hidden; text-overflow: ellipsis;">
                          {{ item.fieldName }}
                        </td>
                        <td>
                          {{ item.fieldType }}
                        </td>
                        <td>
                          <i class="fa-solid fa-check" v-if="item.isMandatory == true"></i>
                          <i class="fa-solid fa-xmark" v-else></i>

                        </td>
                        <td style="max-width:400px; overflow:auto; text-overflow: unset;">
                          {{ item.options }}
                        </td>
                        <td>
                          <div class="Group_top_three_dot">
                            <div class="dropdown">
                              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa-solid fa-ellipsis"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>

                                  <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                    data-bs-target="#UpdateCandidateRegistrationFieldModal"
                                    @click="SelectedCandidateRegistrationFieldData(item.fieldName, item.fieldType, item.isMandatory, item.options)">
                                    Edit Section
                                  </button>
                                </li>
                                <li>
                                  <button type="button" class="btn w-100 text-start">
                                    Delete Section
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade CreatePartnerModal_hp" id="AddCandidateRegistrationFieldModal" tabindex="-1"
        data-bs-keyboard="false" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">


          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto">
                Add Candidate Registration Field

              </h4>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"></button>
            </div>
            <div class="modal-body">



              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">
                  <input type="text" class="form-control" id="" placeholder="Field Name">

                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <select name="" class="form-select input_area select_ca" id="" placeholder="Choose Field Type">

                    <option value='' selected disabled>Choose Field Type</option>
                    <option v-for="item in TestWeightageData" :key="item" :value="item.id">
                      {{ item.sectionName }}</option>
                  </select>


                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <input type="text" class="form-control" id="" placeholder="Enter Options">

                </label>
              </div>
              <div class="form-group mb-4  my-4 ">
                <label class="">Is Mandatory?</label>
                <div class="form-group">
                  <input type="checkbox" disabled hidden="" id="Can_Section Weightage" class="switchInput" />
                  <label class="switch" for="Can_Access_Client0"></label>

                </div>
                <div class="test_instance_button float-end">
                  <button class=" btn btn-primary" type="button">
                    Add
                  </button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="modal fade CreatePartnerModal_hp" id="UpdateCandidateRegistrationFieldModal" tabindex="-1"
        data-bs-keyboard="false" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">


          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title text-centerm-auto">
                Edit Section

              </h4>
              <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"></button>
            </div>
            <div class="modal-body">

              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">
                  <input type="text" class="form-control" id="" placeholder="Field Name" v-model="RegistrationFieldName"
                    disabled>

                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <select name="" class="form-select input_area select_ca" id="" placeholder="Choose Field Type"
                    v-model="RegistrationFieldType">

                    <option value='' selected disabled>Choose Field Type</option>
                    <option v-for="item in TestWeightageData" :key="item" :value="item.id">
                      {{ item.sectionName }}</option>
                  </select>


                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">

                  <input type="text" class="form-control" id="" placeholder="Enter Options" v-model="RegistrationStatus">

                </label>
              </div>
              <div class="form-group mb-4 my-2 ">
                <label class="input-hero">
                  <input type="text" class="form-control" id="" placeholder="Enter Options" v-model="RegistrationOptions">

                </label>
              </div>
              <div class="form-group mb-4  my-4 ">
                <label class="">Is Mandatory?</label>
                <div class="form-group">
                  <input type="checkbox" disabled hidden="" id="Can_Section Weightage" class="switchInput" />
                  <label class="switch" for="Can_Access_Client0"></label>

                </div>
                <div class="test_instance_button float-end">
                  <button class=" btn btn-primary" type="button">
                    Add
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!----------------------------------------------------------------------------------------------->
      <!----------------------------------------------------------------------------------------------->

      <!------------------ Disclaimer ------------------------------------------------------------------>
      <!-- <div class="accordion-item border-radius-kj">
       <div class="accordion-item border-radius-kj">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
                data-bs-target="#DisclaimerCollapse" aria-expanded="true" aria-controls="DisclaimerCollapsee">
                <div>
                  <h4>
                    Disclaimer
                    <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
                  </h4>
                  <p class="accordion-button-p-kj">
                    Your application to indicate that you do not claim exclusive rights to an unregistrable
                  </p>
                </div>
              </button>
            </h2>
            <div id="DisclaimerCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne"
              data-bs-parent="#FaqAccordion">
              <div class="accordion-body p-0 managementPartner-Acc-p-kj">
                <div class="px-3">
                  <div class="my-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus.
                    Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus,
                    ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit
                    amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent
                    per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
                    enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex.
                    Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem.
                    Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.
                  </div>
                </div>
              </div>
            </div>
          </div> -->
    </section>
  </div>

  <!--  Invite Candidates model -->
  <inviteCandidate
    v-if="this.openInvite == true"
    :testname="this.testSummary.testName"
    :testid="this.routetestId"
    :testclientid="this.testclientId"
    @clicked="closeinvite"
  />
  <!-- Archive  -->
  <div
    class="modal fade"
    id="DeactivatePartnerModal"
    tabindex="-1"
    aria-labelledby="DeactivatePartnerModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="">
            <h3 class="mb-3">ActiveTest ?</h3>
            <p class="text-muted">
              Are you sure you want to Active
              <strong>
                <!-- Sales Assessment </strong>group. -->
                {{ this.testSummary.testName }}</strong
              >
              group.
            </p>
          </div>
          <div class="Deactivate_buttons row mt-5 justify-content-center">
            <button
              class="col-5 Deactivate_Cancel_buttons"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="col-5 btn Deactivate_Deactivate_buttons"
            >
              Active
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import inviteCandidate from "./inviteCandidate.vue";
import moment from "moment";
import { superadmin } from "../utils/helper";
// import { AccessPlanFeatures } from '../platformFeatures/featureUtils';

// import Multiselect from "@vueform/multiselect/";
export default {
  name: "ActiveTestInviteCandidates",
  components: {
    inviteCandidate //,
    // Multiselect
  },
  async created() {
    // await AccessPlanFeatures(this.user.extId,49,'Active Test Access');
    window.addEventListener("resize", this.myEventHandler);
    this.screenWidth = window.outerWidth;
    await this.getActiveTestData();

    // var testdetail = await this.activetestdetail({
    //   testId: this.routetestId,
    // });
    // (this.testSummary = testdetail.object.data),
    // (this.tname = this.testSummary.testName);
    // this.testTime = this.testSummary.tesTime.split(":", 2)[1];

    // this.durationList = await this.GetCoolingPeriodDuration({
    //   clientId: this.routeclientId,
    // });

    // this.startTime = this.testSummary.startTime;
    // this.endTime = this.testSummary.endTime;
    // this.CheckSettingDays = this.testSummary.coolingPeriodDays;

    // this.durationBlock = this.CoolingPeriodDays === null ? "" : this.CheckSettingDays;
    // if (this.CheckSettingDays != null) {
    //   this.CoolingPeriodDurationStatus = true;
    //   this.verificationBlock = [];
    // }

    // if (this.startTime != null && this.endTime != null) {

    //   const offsetMatch = this.user.timezone.match(/([+-]\d{2}):?(\d{2})?/);
    //   const hours = parseInt(offsetMatch[1]);
    //   const minutes = offsetMatch[2] ? parseInt(offsetMatch[2]) : 0;
    //   const offset = hours * 60 + minutes;

    //   const startTimeWithGMT = moment.utc(this.startTime).utcOffset(offset);
    //   this.ConvertedStartTime = startTimeWithGMT.format("YYYY-MM-DD HH:mm");
    //   const endTimeWithGMT = moment.utc(this.endTime).utcOffset(offset);
    //   this.ConvertedEndTime = endTimeWithGMT.format('YYYY-MM-DD HH:mm');

    //   if (this.ConvertedStartTime != null) {
    //     this.TestDurationStatus = true;
    //   }

    // }
    // else {
    //   this.ConvertedStartTime = null;
    //   this.ConvertedEndTime = null;
    // }

    // this.scheduledStartTime = this.scheduledStartTime === null ? "" : this.ConvertedStartTime;
    // this.scheduledEndTime = this.scheduledEndTime === null ? "" : this.ConvertedEndTime;


    // var TestWeightageValue = await this.GetTestWeightage({
    //   testId: this.routetestId,
    // });
    // if (TestWeightageValue != null) {
    //   this.TestWeightageData = TestWeightageValue.object.data;
    // }

    // var a = await this.GetCandidateAdditionalDetails({
    //   testId: this.routetestId,
    // });
    // if (a != null) {
    //   this.CandidateAdditionalDetailsList = a.object.data;

    // }

    // var data = await this.GetLanguageWeightageDetails({ testId: this.routetestId, });

    // if (data != null) {
    //   this.LanguageWeightageDetailsList = data.object.data;
    // }



    // this.getCoolingPeriodSettingData();

    // this.TestWeightage();
    // this.totalWeightage();
  },

  async mounted() {
    this.screenWidth = window.outerWidth;

    await this.getActiveTestData();
    if (this.user.clientId == 1001) {
      var roles = await this.AllActiveTest({
        Search: "",
        clientId: this.user.clientId,
        limit: 100,
        sortCol: "testid",
        offset: 1,
        sortdir: "desc",
      });
      var x = roles.object.data.testSummary;

      x.forEach((element) => {
        if (element.testId == this.routetestId) {
          this.testclientId = element.clientId;
        }
      });
    } else {
      this.testclientId = "";
    }
    // var testdetail = await this.activetestdetail({
    //   testId: this.routetestId,
    // });
    // (this.testSummary = testdetail.object.data),
    //   (this.tname = this.testSummary.testName);
    // this.testTime = this.testSummary.tesTime.split(":", 2)[1];

    var testsection = await this.activetestsection({
      testId: this.routetestId,
    });
    this.testSection = testsection.object.data;

    // var generalsettings = await this.activetestgeneralsetting({
    //   testId: this.routetestId,
    // });
    // this.generalSettings = generalsettings.object.data;

    // var proctorsettings = await this.activetestproctorsettings({
    //   testId: this.routetestId,
    // });
    // this.proctorSettings = proctorsettings.object.data;

    // this.options = await this.CoolingPeriodSettingData.object.data.settings.
    //   settings.map((i) => ({
    //     value: i.groupId,
    //     label: i.elementName,
    //   }));

  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    limitTestName:{
      get(){
        return this.tname.slice(0,60);
      },
      set(value){
        this.tname=value.slice(0,60);
      }
    },
    // uniqueParameters() {
    //   const parameters = [];
    //   for (const section of this.LanguageWeightageDetailsList) {
    //     if (!parameters.includes(section.parameterName)) {
    //       parameters.push(section.parameterName);
    //     }
    //   }
    //   return parameters;
    // },
    // sections() {
    //   const sections = [];
    //   for (const section of this.LanguageWeightageDetailsList) {
    //     const existingSection = sections.find((s) => s.sectionId === section.sectionId);
    //     if (!existingSection) {
    //       sections.push({ sectionId: section.sectionId, sectionName: section.sectionName });
    //     }
    //   }
    //   return sections;
    // },
    // totalWeightage() {
    //   let totalWeightage = 0;
    //   this.TestWeightageData.forEach(item => {
    //     if (item.weightageValue !== null && !isNaN(item.weightageValue)) {
    //       totalWeightage += item.weightageValue;
    //     }
    //   });
    //   return totalWeightage;
    // },
  },
  data() {
    return {
      isShowing: false,
      testSummary: [],
      testSection: [],
      generalSettings: [],
      proctorSettings: [],
      openInvite: false,
      testTime: "",
      testDuration: {},
      tname: "",
      testclientId: "",
      routetestId: window.atob(this.$route.params.id),
      routeclientId: window.atob(this.$route.params.clientId),
      routesearch: window.atob(this.$route.query.search),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      startTime: '',
      endTime: '',
      Getdays: 0,
      CheckSettingDays: 0,
      CheckSettingValidationType: [],
      GetValidationData: [],
      GetDaysData: 0,
      scheduleTest: {},
      Start: '',
      End: '',
      scheduledStartTime: '',
      scheduledEndTime: '',
      TestDurationStatus: false,
      CoolingPeriodDurationStatus: false,
      minDateTime: new Date().toISOString().slice(0, 16),
      RequiredCurrentDateTime: moment(new Date().toLocaleString()).format(this.minDateTime).slice(0, 16),
      selectedPeriod: null,
      durationList: {
        object: {
          data: []
        }
      },
      id: null,
      Name: null,
      Days: 0,
      durationBlock: 0,
      radio1: '',
      radio2: '',
      CustomDays: null,
      Default_Mode: false,
      Custom_Mode: false,
      verificationBlock: [],
      DurationId: 0,
      options: [],
      timezone: '',
      ConvertedStartTime: '',
      ConvertedEndTime: '',
      SectionName: "",
      SectionWeightageValue: 0.0,
      SectionMinScore: 0.0,
      SectionMaxScore: 0.0,
      CandidateAdditionalDetailsList: [],
      RegistrationFieldName: '',
      RegistrationFieldType: [],
      RegistrationStatus: '',
      RegistrationOptions: '',
      currentTime: new Date(),
      TestWeightageData: [],
      LanguageWeightageDetailsList: [],
      selectedWeightage: 0,
      paraName: '',
      sectName: '',
      editStatus: false,
      screenWidth: 0,


    };
  },
  methods: {
    ...mapActions([
      "activetestdetail",
      "activetestsection",
      "activetestgeneralsetting",
      "activetestproctorsettings",
      "AllActiveTest",
      "CreateTestScheduler",
      "UpdateTestDuration",
      "GetCoolingPeriodDuration",
      "CreateCoolingPeriodSetting",
      "UpdateCoolingPeriod",
      "GetCoolingPeriodSetting",
      "UserEditData",
      "GetTestWeightage",
      "GetCandidateAdditionalDetails",
      "GetLanguageWeightageDetails",
      "postChangeTestName"
    ]),
    superadmin,

  

    shareTest() {

      var parent = localStorage.getItem("isParent");
      if (
        !superadmin() &&
        parent == true &&
        this.user.clientId != this.routeclientId
      ) {
        return false;
      } else {
        return true;
      }
    },
    async toggleTestDuration() {
      this.TestDurationStatus = !this.TestDurationStatus;
    },
    getFirstIndex(parameterName) {
      for (let i = 0; i < this.LanguageWeightageDetailsList.length; i++) {
        if (this.LanguageWeightageDetailsList[i].parameterName === parameterName) {
          return i;
        }
      }
      return -1;
    },

    async getActiveTestData(){
      var testdetail = await this.activetestdetail({
      testId: this.routetestId,
    });
    (this.testSummary = testdetail.object.data),
    (this.tname = this.testSummary.testName);
    // this.testTime = this.testSummary.tesTime.split(":", 2)[1];
    this.testTime = this.testSummary.tesTime;

    },

    dateConvert: function (value) {
      return moment(value, "HH:mm:ss").format((moment(value, "HH:mm:ss").hour() === 0 ? "m [min]" : "h [hr] m [min]"));
    },
    toggleEdit() {
      this.editStatus = !this.editStatus;
    },
    handleInput(event) {
      if (event.target.value.length > 60) {
        event.target.value = event.target.value.slice(0, 60);
      }
// if (event.key === '%') {
//   event.preventDefault();
// }
    },
    // update(e) {
    //   if (this.$refs.editTestName.innerText.length > 60) {        
    //     this.tname = e.target.innerText;
    //     this.$refs.editTestName.innerText = this.$refs.editTestName.innerText.substring(0, 60);
    //   }
    //},
    myEventHandler(e) {
      this.screenWidth = window.outerWidth;
      e.preventDefault();
    },
    getParameterWeightage(section, parameter) {
      const matchingSection = this.LanguageWeightageDetailsList.find(
        (s) => s.sectionId === section.sectionId && s.parameterName === parameter
      );
      return matchingSection ? matchingSection.weightageValue : null;
    },


    async SelectedEvaWeightageData(Weightage, paraName, sectName) {
      this.selectedWeightage = Weightage;
      this.paraName = paraName;
      this.sectName = sectName;
    },
    async SelectedSectionData(name, weightageValue, minScore, maxScore) {

      this.SectionName = name;
      this.SectionWeightageValue = weightageValue;
      this.SectionMinScore = minScore;
      this.SectionMaxScore = maxScore;


    },
    isBeforeMinDateTime() {
      if (!this.scheduledStartTime) {
        return false;
      }
      return this.scheduledStartTime < this.RequiredCurrentDateTime;
    },
    async SelectedCandidateRegistrationFieldData(name, type, isMandatory, options) {

      this.RegistrationFieldName = name;
      this.RegistrationFieldType = type;
      this.RegistrationStatus = isMandatory;
      this.RegistrationOptions = options;


    },

    async editTestNames(){
      this.editStatus = !this.editStatus;
      await this.postChangeTestName({testId:this.routetestId , testName: this.tname});
    },
    // async getCoolingPeriodSettingData() {
    //   try {
    //     this.CoolingPeriodSettingData = await this.GetCoolingPeriodSetting({
    //       clientId: this.routeclientId,
    //       testId: this.routetestId
    //     });

    //     if (this.CoolingPeriodSettingData.object != null) {

    //       var data = this.CoolingPeriodSettingData.object.data;
    //       this.CheckSettingValidationType = data?.settings.map((i) => i.groupId);



    //     } else {
    //       this.CheckSettingDays = "";
    //       this.CheckSettingValidationType = "";
    //       this.GetValidationData = "";
    //     }

    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // preventCoolingPeriodCustomNumbers(event) {
    //   const input = event.target.value;
    //   if (event.key === '-' || event.key === '+' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'e' || event.key === '.') {
    //     event.preventDefault();
    //   }

    //   if ((input.length >= 4 && event.key !== 'Backspace')) {
    //     event.preventDefault();
    //   }

    // },






    // preventNegativeNumbers(event) {
    //   const input = event.target.value;
    //   if (event.key === '-' || event.key === '+' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'e') {
    //     event.preventDefault();
    //   }

    //   if ((input.length >= 4 && event.key !== 'Backspace')) {
    //     event.preventDefault();
    //   }

    // },

    formattedTime(sectionTime) {
      const duration = moment.duration(sectionTime);
      const minutes = duration.asMinutes();
      return `${minutes} mins`;
    },
    preventWeightageNumbers(event) {
      const input = event.target.value;

      if (event.key === '-' || event.key === '+' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'e') {
        event.preventDefault();
      }

      if (input.length >= 4 && event.key !== 'Backspace') {
        event.preventDefault();
      }

      if (input.length === 0 && event.key >= '1' && event.key <= '9') {
        event.target.value = '0.' + event.key;
        event.preventDefault();
      }

      if (input.length === 1 && input.startsWith('0') && event.key !== 'Backspace') {
        event.target.value = '0.' + input[1] + event.key;
        event.preventDefault();
      }

      if (input.length === 2 && !input.includes('.') && event.key !== 'Backspace') {
        event.target.value = '0.' + input + event.key;
        event.preventDefault();
      }
    },




    async CheckStatus(CheckActive) {
      let arr = []
      CheckActive.forEach((element) => {
        if (element.value === true) {
          arr.push({
            value: element.label,
            label: element.label
          });
        }
      })
      return arr;
    },


    async toggleCoolingPeriodDuration() {
      this.CoolingPeriodDurationStatus = !this.CoolingPeriodDurationStatus;
    },
    startGreaterError() {
      return this.scheduledStartTime > this.scheduledEndTime
    },

    async sendData() {
      await this.CreateTestScheduler({
        testId: this.routetestId,

        duration: {
          start: this.scheduledStartTime,
          end: this.scheduledEndTime,
          durationActive: true
        },
      });
      window.location.reload();

    },



    async sendCoolingPeriodData() {

      var CustomDays = this.CustomDays == null ? 0 : this.CustomDays;
      await this.CreateCoolingPeriodSetting({
        CoolingPeriodType: 2,
        clientId: this.routeclientId,
        testId: this.routetestId,

        duration: {
          name: CustomDays + " days",
          days: CustomDays,
          durationId: this.durationBlock
        },
        validationSetting: {
          type: 2,
          elements: (this.verificationBlock)
        }
      });
      window.location.reload();

    },

    async UpdateCoolingPeriodData() {
      var CustomDays = this.CustomDays == null ? 0 : this.CustomDays;
      await this.UpdateCoolingPeriod({
        clientId: this.routeclientId,
        testId: this.routetestId,
        duration: {
          name: CustomDays + " days",
          days: CustomDays,
          isActive: this.CoolingPeriodDurationStatus,
          durationId: this.durationBlock

        },
        validationSetting: {
          type: 2,
          elements: (this.verificationBlock)
        },
        settingIsActive: this.CoolingPeriodDurationStatus
      });
      window.location.reload();

    },

    async updateData() {
      await this.UpdateTestDuration({
        testId: this.routetestId,
        duration: {
          start: this.scheduledStartTime,
          end: this.scheduledEndTime,
          isActive: this.TestDurationStatus,
          durationId: 0,
          durationActive: this.TestDurationStatus
        }
      })
      window.location.reload();

    },

    validate() {
      const scheduledStartTime = new Date(this.scheduledStartTime)
      const scheduledEndTime = new Date(this.scheduledEndTime)

      if (this.scheduledStartTime != null && this.scheduledEndTime != null) {
        return scheduledStartTime > scheduledEndTime;
      }
    },



    validateUpdate() {
      const scheduledStartTime = new Date(this.scheduledStartTime)
      const scheduledEndTime = new Date(this.scheduledEndTime)
      if (this.scheduledStartTime != null && this.scheduledEndTime != null) {

        return scheduledStartTime > scheduledEndTime
      }
    },
    CheckNull() {
      return !this.scheduledStartTime || !this.scheduledEndTime
    },

    selectPeriod(value) {
      this.selectedPeriod = value
    },

    async resetData() {
      if (this.ConvertedStartTime != null) {
        window.location.reload();
      } else {
        this.scheduledStartTime = null;
        this.scheduledEndTime = null;
      }
    },

    async resetValues() {
      window.location.reload();

    },


    async coolingPeriodResetData() {

      window.location.reload();


      // this.verificationBlock = null;
      // this.durationBlock = null;
      // this.CheckSettingValidationType = null;




    },

    invite() {
      this.openInvite = true;
    },
    encode(item) {
      return window.btoa(item);
    },
    closeinvite(val) {
      this.openInvite = val;
    },
    // viewCandidates() {
    //     this.$router.push({
    //         name: 'ActiveTestViewCandidates',
    //         params: { id: this.routetestId , search:this.input}
    //     });
    // }
  },
};
</script>

<style scoped>
.bordered {
  border: 1px solid #7c68ee;
  padding: 4px; 
  background-color: #FFFFFF;
  width: 700;
  border-radius: 5px;
}
.datepicker table thead th,
.table thead th {
  text-transform: capitalize;
  font-size: 15px;
}

.width-control {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

td {
  padding: 10px;
  /* Adds 10 pixels of padding to all cells */
}

label {
  display: block;
  margin-bottom: 20px;
}

div {
  margin-bottom: 10px;
  /* Adjust the margin to your desired value */
}

input {
  display: block;
  margin-bottom: 20px;
}

.d-flex.justify-content-between.align-items-center.mb-2>* {
  margin-right: 100px;
}

.flex-container {
  display: flex;
}

#item1 {
  margin-right: 30px;
}

.btn-primary2 {
  border-radius: 50px !important;
  border: 2px solid #F9FAFB !important;
  background: #F9FAFB !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
}
.btn-primary2:hover {
  border-radius: 50px !important;
  border: 2px solid #7c68ee !important;
  background: #F9FAFB !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
}

@media (max-width: 480px) {
  .font-size-MV {
    font-size: 13px;
  }
}
</style>
