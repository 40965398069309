<template>
  <div class="page-content">
    <!-- header -->
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-2"
    >
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link
                :to="{
                  name: 'ActiveTestViewCandidatesNew',
                  params: {
                    clientId: encode(this.clientId),
                    isParent: encode(isParent), //update
                  },
                  query: {
                    search: encode(this.routesearch),
                    offset: encode(this.routeoffset),
                    current: encode(this.routecurrent),
                  },
                }"
                class="p-0 Group_top_three_dot_dropdown-active btn-back-arrow-kj"
                type="button"
                style="line-height: 2.6"
              >
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 fw-bolder">Report</h4>
          <h6 class="text-muted">
            {{ this.testName }} <i class="fa-solid fa-angle-right mx-2"></i>
            {{ this.name }}
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="top_heading_CreatePartner_btn d-block mx-3"
          data-bs-toggle="modal"
          data-bs-target="#ConsolidatedSheet"
        >
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <!-- <button
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
                @click="savePdf()"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/download-icon.png"
                />
              </button> -->
              <a :href="reportUrl" target="_blank">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </a>
              <!-- <router-link :to="{
                name: 'ProctorPDF',
                query: {
                  candidateId: encode(this.candidateId),
                  testId: encode(this.testId),
                  name: encode(this.name),
                },
              }">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </router-link> -->
            </div>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
                        <div class="dropdown">
                          <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fa-solid fa-ellipsis-vertical fa-lg" style="color: #7c7c7c;"></i>               
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <a :href="compeleteReportUrl" target="_blank">
                              <button type="button" class="btn w-100 text-start">
                                Download Consolidated Report
                              </button>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
        </div>
      </div>
    </div>

    <div class="row Report_card_section">
      <div class="col-md-12">
        <div class="card active-card">
          <!--Header Component-->
          <div class="card mb-3">
            <ActiveTestHeaderVue
              :active="this.activated"
              :candidateId="this.candidateId"
              :testId="this.testId"
              :isParent="this.isParent"
              :clientId="this.clientId"
              :id="this.routesearch"
              :current="this.routecurrent"
              :offset="this.routeoffset"
            />
          </div>
          <div class="card">
            <div class="Report_basic_info_area my-4">
              <div class="Report_basic_info_heading">
                <h4>
                  Hardware history
                  <img
                    src="../../public/dashboard-assets/images/M7/Actions.png"
                    width="25"
                    class="cursor-pointer ms-2"
                    alt=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Includes features such as the platform, IP address, browser used to give the test as well as proctoring details. "
                  />
                </h4>
              </div>
              <div class="row Report_basic_info_detial_area">
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted">Platform</label>
                  <h5 class="fw-600">{{ hardwareHistory.platform }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted">IP</label>
                  <h5 class="fw-600">{{ hardwareHistory.ip }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted">Browser</label>
                  <h5 class="fw-600">{{ hardwareHistory.browser }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted">Proctoring Details</label>
                  <h5 class="fw-600">
                    {{ hardwareHistory.proctoringDetails }}
                  </h5>
                </div>
              </div>
            </div>

            <div
              class="Report_basic_info_area my-4 Proctor_video_section"
              v-if="this.ProctorData?.videoCaptureEnabled == true"
            >
              <div class="Report_basic_info_heading">
                <h4>
                  Video
                  <img
                    src="../../public/dashboard-assets/images/M7/Actions.png"
                    width="25"
                    class="cursor-pointer ms-2"
                    alt=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Refers to the recording of moving visual images of the candidate while giving the test."
                  />
                </h4>
              </div>
              <div class="row mt-3 Proctor_video_slider">
                <div class="col-xl-4 col-lg-6 col-md-12">
                  <div class="Proctor_video_item">
                    <video width="100%" height="240" controls>
                      <!-- <source src="https://youtu.be/bQES_F6c7c8" type="video/mp4" /> -->
                      <source
                        v-bind:src="VideosData[0]?.mediaURL"
                        type="video/mp4"
                      />
                    </video>
                    <div class="video_overley_text">
                      <i class="fa-solid fa-play"></i> video
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div
              class="Report_basic_info_area my-4 Proctor_video_section"
              v-if="this.ProctorData?.pictureCaptureEnabled"
            >
              <div class="Report_basic_info_heading">
                <h4>snapshots  <img
                  src="../../public/dashboard-assets/images/M7/Actions.png"
                  width="25"
                  class="cursor-pointer ms-2"
                  alt=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Snapshots taken of the candidate while taking the assessment."
                /></h4>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <div v-if="isLoading" class="loader">Loading...</div>
                  <div v-else class="Proctor_snapshots_slider">
                    <img
                      class="Proctor_snapshots_slider-img"
                      v-for="SnapImage in snapshots"
                      :key="SnapImage"
                      v-bind:src="SnapImage.mediaURL"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <div
              class="Report_basic_info_area my-4 Proctor_video_section"
              v-if="this.ProctorData?.pictureCaptureEnabled"
            >
              <div class="Report_basic_info_heading">
                <h4>
                  screenshots
                  <img
                    src="../../public/dashboard-assets/images/M7/Actions.png"
                    width="25"
                    class="cursor-pointer ms-2"
                    alt=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Photographs taken of the candidate while taking the assessment."
                  />
                </h4>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <Carousel :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="slide in screenshots" :key="slide">
                      <div class="carousel__item">
                        <img :src="slide.mediaURL" />
                      </div>
                    </Slide>
                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>
                </div>
              </div>
            </div>

            <div
              class="Report_basic_info_area my-4"
              v-if="this.ProctorData?.logsEnabled == true"
            >
              <div class="Report_basic_info_heading">
                <h4>
                  logs
                  <img
                    src="../../public/dashboard-assets/images/M7/Actions.png"
                    width="25"
                    class="cursor-pointer ms-2"
                    alt=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Includes various log details such as logged time, entry type, logged action and description of the same."
                  />
                </h4>
              </div>
              <div class="table-responsive Report_Proctor_logs_Table mt-3">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Logged Time</th>
                      <th>Entry Type</th>
                      <th>Logged Action</th>
                      <th>Logged Action Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in logsData"
                      :key="item"
                      :class="{
                        color_FF2A87: item.isFlagged,
                        'border-proctor': item.isFlagged,
                      }"
                    >
                      <td>
                        {{
                          moment(item.loggedTime).format(
                            "DD/MM/YYYY, HH:mm:ss a"
                          )
                        }}
                      </td>
                      <td>{{ item.entryType }}</td>
                      <td>{{ item.action }}</td>
                      <td>
                        {{ item.actionDescription }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="Report_basic_info_area my-4">
            <div class="Report_basic_info_heading">
              <h4>DISCLAIMER</h4>
            </div>
            <div class="mt-3">
              <!-- <p>
                {{ disclaimer }}
              </p> -->
              <span v-html="disclaimer"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showpdf">
    <ProctorPDF
      :testId="this.testId"
      :candidateId="this.candidateId"
      :name="this.name"
      :pdfData="this.overviewPdf"
    />
    <!-- v-show="false" ref="DownloadComp" -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import ActiveTestHeaderVue from "./ActiveTestHeader.vue";
import ProctorPDF from "./ProctorPDF.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import store from "../store";
import jsPDF from "jspdf";
import Base64Images from "../utils/base64Image.js";

export default {
  name: "ActiveTestReportProctor",
  components: {
    Carousel,
    Slide,
    Navigation,
    ActiveTestHeaderVue,
    ProctorPDF,
  },
  data: () => ({
    activated: "Proctor",
    hardwareHistory: "",
    ProctorData: "",
    VideosData: "",
    logsData: [],
    screenshots: "",
    candidateId: null,
    testId: null,
    clientId: null,
    isParent: null,
    routesearch: null,
    routeoffset: null,
    routecurrent: null,
    testName: "",
    clientCompany: "",
    Companylogo: "",
    namePdf: "",
    testNamePDF: "",
    disclaimer: "",
    isLoading: false,

    showpdf: false,
    name: "",
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },

    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      600: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // 700px and up
      768: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: "start",
      },
      // 1024 and up
      1100: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      // 1024 and up
      1200: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    },
    reportUrl: "",
    compeleteReportUrl:""
  }),

  async created() {
    this.moment = moment;
    this.candidateId = window.atob(this.$route.params.candidateId);
    this.clientId = window.atob(this.$route.query.clientId);
    this.testId = window.atob(this.$route.params.testId);
    this.isParent = window.atob(this.$route.query.isParent);
    this.routesearch = window.atob(this.$route.query.id);
    this.routeoffset = window.atob(this.$route.query.offset);
    this.routecurrent = window.atob(this.$route.query.current);
    this.reportUrl = window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=Proctor";
    this.compeleteReportUrl= window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=consolidated";

  },

  async mounted() {
    (this.name = window.atob(this.$route.query.name)), await this.AllProctor();
    await this.History();
    await this.ImageData();
    await this.VideoData();
    await this.LogsModel();
    await this.screenshotsImageData();
    await this.getInfo(); 
    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },

  methods: {
    ...mapActions([
      "HardwareHistory",
      "ImageandVideos",
      "ProctoredLogsModel",
      "GetsAllProctor",
      "getBasicInfo",
    ]),
  

    async History() {
      var history = await this.HardwareHistory(this.candidateId);
      if (history?.status) {
        this.hardwareHistory = history?.object?.data;
      } else {
        this.hardwareHistory = null;
      }
    },
    encode(item) {
      return window.btoa(item);
    },
    async ImageData() {
      if (this.ProctorData?.pictureCaptureEnabled) {
        var model = {
          id: this.candidateId,
          type: "images",
        };
        this.isLoading = true; // Show the loader before API request
        var snaps = await this.ImageandVideos(model);
        if (snaps?.status) {
          this.snapshots = snaps?.object?.data?.mediaUrls;
          this.isLoading = false; // Hide the loader after API request is completed
        } else {
          this.snapshots = [];
          this.isLoading = false; // Hide the loader after API request is completed
        }
      }
    },

    async screenshotsImageData() {
      if (this.ProctorData?.pictureCaptureEnabled == true) {
        var model = {
          id: this.candidateId,
          type: "images",
        };
        var screens = await this.ImageandVideos(model);
        if (screens?.status) {
          this.screenshots = screens?.object?.data?.mediaUrls;
        } else {
          this.screenshots = [];
        }
      }
    },

    async VideoData() {
      if (this.ProctorData?.videoCaptureEnabled) {
        var model = {
          id: this.candidateId,
          type: "videos",
        };
        var Videos = await this.ImageandVideos(model);
        if (Videos?.status) {
          this.VideosData = Videos?.object?.data?.mediaUrls;
        } else {
          this.VideosData = [];
        }
      }
    },

    async LogsModel() {
      if (this.ProctorData?.logsEnabled == true) {
        var logs = await this.ProctoredLogsModel(this.candidateId);
        if (logs.status) {
          this.logsData = logs?.object?.data.logs;
        } else {
          this.logsData = [];
        }
      }
    },

    async AllProctor() {
      var proctor = await this.GetsAllProctor(this.candidateId);
      if (proctor?.status) {
        this.ProctorData = proctor?.object.data;
      } else {
        this.ProctorData = [];
      }
    },
    async getInfo() {
      var info = await this.getBasicInfo(this.candidateId);
      if (info?.status) {
        this.BasicInfoData = info.object.data;
        this.disclaimer = this.BasicInfoData.disclaimer;
        this.testName = this.BasicInfoData.testName;
        this.clientCompany = this.BasicInfoData.clientCompanyLogo;
        this.Companylogo = this.BasicInfoData.companyLogo;
        this.namePdf = this.BasicInfoData.name;
        this.testNamePDF = this.BasicInfoData.testName;
      } else {
        this.BasicInfoData = null;
      }
    },

    async savePdf() {
      store.state.loader.isLoading = true;
      this.showpdf = true;
      // const doc = new jsPDF("p", "mm", "a4" ); // Create a new instance of jsPDF with A4 dimensions
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        // autoPaging: "text"
      }); // Create a new instance of jsPDF with A4 dimensions
      const namePdf = this.namePdf;
      const testName = this.testNamePDF;
      const Companylogo = this.Companylogo;
      const clientCompany = this.clientCompany;
      setTimeout(() => {
        const element = document.getElementById("proctor");
        const container = document.createElement("div");
        // Append the container to the element
        element.appendChild(container);

        doc.html(element, {
          margin: [25, 0, 25, 0],
          x: 0,
          y: 0,
          autoPaging: "text",
          callback: function (pdf) {
            // footer start
            const footerContainer = document.createElement("div");
            // Append the footer container to the element
            element.appendChild(footerContainer);

            const pageCount = doc.internal.getNumberOfPages(); // Get the total number of pages in the PDF document

            //Ankit code
            //  const headerMarginTop = 10; // Set the top margin for the header
            //const footerMarginBottom = 10;
            for (let i = 1; i <= pageCount; i++) {
              doc.setPage(i);
              // header start
              if (i === 1) {
                var imageUrl = Base64Images.Background;
                // Add the background image
                doc.addImage(
                  imageUrl,
                  "PNG",
                  0,
                  0,
                  doc.internal.pageSize.getWidth(),
                  doc.internal.pageSize.getHeight()
                );
                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute("src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image
                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                // console.log(img1)
                // logo-2
                const img2 = document.createElement("img");
                img2.setAttribute("src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(
                  img2.src,
                  "JPEG",
                  imageX2,
                  imageY2,
                  image2Width,
                  image2Height
                );
              } else {
                // header 2
                doc.setFillColor(232, 247, 250); // Set the fill color to red (change the RGB values as per your desired color)
                doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, "F"); // Draw a filled rectangle as the header background

                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute("src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image

                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                doc.setFontSize(10);

                // Set the header text margin

                // Specify the content of the header text
                const headerText = `${testName}`;

                // Calculate the x-position to center the header text
                const textWidth =
                  (doc.getStringUnitWidth(headerText) *
                    doc.internal.getFontSize()) /
                  doc.internal.scaleFactor;
                const x = (doc.internal.pageSize.getWidth() - textWidth) / 2;

                // Add the header text at the centered position
                doc.text(headerText, x, 10);

                // logo-2

                const img2 = document.createElement("img");
                img2.setAttribute("src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(
                  img2.src,
                  "JPEG",
                  imageX2,
                  imageY2,
                  image2Width,
                  image2Height
                );
              }
              // footer start
              doc.setFontSize(15);
              doc.setTextColor(41, 46, 52, 1);
              const img3 = document.createElement("img");
              img3.setAttribute(
                "src",
                Base64Images.Footer
              );

              const image3Width = doc.internal.pageSize.width; // Width of the image (equal to the page width)
              const image3Height = 20; // Height of the image
              const imageX3 = 0; // X position of the image (start from the left)
              const imageY3 = doc.internal.pageSize.height - image3Height;
              doc.addImage(
                img3,
                "JPEG",
                imageX3,
                imageY3,
                image3Width,
                image3Height
              );

              const footerText = "";
              const pageNumber = "Proctor " + " | " + i;
              const textWidth = doc.getTextWidth(footerText); // Calculate the width of the footer text
              const xPos = doc.internal.pageSize.width - textWidth - 40; // 10 is the margin from the right side
              // Add footer text aligned to the right
              doc.text(footerText, xPos, doc.internal.pageSize.height - 10);
              // Add page number aligned to the right
              doc.text(pageNumber, xPos, doc.internal.pageSize.height - 5);
            }
            // end footer
            const pdfDataUri = pdf.output("datauristring");
            const link = document.createElement("a");
            link.href = pdfDataUri;
            link.download = `Proctor_${namePdf}.pdf`;

            store.state.loader.isLoading = false;
            link.click();
          },
        });
        setTimeout(() => {
          this.showpdf = false;
        }, 1 * 60 * 1000);
      }, 1 * 60 * 1000);
    },
  },
};
</script>

<style>
.carousel {
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 8px;
}

.carousel__item {
  min-height: 180px;
  width: 100%;
  background-color: #ffffff;
  color: white;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  width: 250px !important;
  height: 250px !important;
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  background-color: #fff7;
  color: #7c68ee;
  background: #ffffff;
  border: 1px solid #edeaff;
  border-radius: 32px;
  font-size: 19px;
}

.carousel__prev--disabled {
  display: none;
}
.border-proctor {
  border-top: 1px solid #ff2a87 !important;
  border-bottom: 1px solid #ff2a87 !important;
}
.carousel * {
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
}
.carousel__next {
  width: 3%;
}
.carousel__prev {
  width: 3%;
}
.carousel__prev,
.carousel__next {
  background-color: #fff7;
  color: #7c68ee;
  background: #ffffff;
  border: 1px solid #edeaff;
  border-radius: 32px !important;
  font-size: 19px;
}
</style>
