<template>
  <!-- style="filter: blur(8px);" -->
  <div class="page-content">
    <div class="card p-0" style="border-radius: 15px;" v-if="this.show">
      <div class="table-responsive">
        <table class="table text-center">
          <thead>
            <tr>
              <th>
                <span style="display: flex; align-items: center; justify-content: center; margin-bottom: 25%;">
                  <div style="color: #7c68ee; font-size:17px; display: block; margin-right: 10%;">Monthly</div>
                  <div class="form-check form-switch">
                    <input class="form-check-input custom-toggle" type="checkbox" id="flexSwitchCheckChecked" checked
                      v-model="planType">
                  </div>
                  <div style="color: #7c68ee; font-size:17px; display: block;margin-left: 4%; ">Yearly</div>
                </span>
                <!-- <span style="display: block; margin-top: 5%;">Per Organization/Month</span> -->
                <span
                  style="display: block; margin-top: 12%; color: #7c68ee;font-size:20px; font-weight: 600x;">Features</span>
              </th>

              <th v-for="plan in filteredPlans" :key="plan" >
  <span class="planheading">{{ plan.displayName }}</span>
  <span style="display: block; margin-top: 10%;">
    <button type="button" class="currentPlanButton" v-if="this.currentPlan==plan.planCode" disabled>Current Plan</button>
    <button type="button" class="UpgradeButton" @click="paymentPage(plan.planCode)" v-else>Select Plan</button>

  </span>
  <span style="display: block; margin-top: 5%; color: #7c68ee; font-size:20px; font-weight: 600;">
    {{ this.fetchCurrency(plan.currencyCode) }} {{ plan.price }}
    <span style="font-size: 12px;">per Candidate</span>
  </span>
  <!-- <span  style="font-size: 10px;">billed at $5,640 per year</span> -->

</th>

              <th>
                <span class="planheading">Enterprise</span>
                <span style="display: block; margin-top: 9%;">
                  <!-- <button type="button" class="UpgradeButton" >
                    </button> -->

                    <button type="button" class="currentPlanButton" v-if="this.currentPlan=='Enterprise'" disabled>Current Plan</button>
                    <a :href="'https://calendly.com/nkamble/pmaps-quick-support-new-requirements'" v-else type="button" class="UpgradeButton"
                    target="_blank" >
                    Connect Now
                  </a>

                

                </span>
                <span style="display: block;  margin-top: 7.5%; color: #a898ff; font-size:17.5px; font-weight: 600;">Custom
                  Plan</span>
              </th>
            </tr>
          </thead>

        <tbody v-for="module in PlanFeatures" :key="module.moduleName">
          <tr>
            <td style="font-size: 26px; text-align: left; font-weight: 600;">
              {{ module.moduleName }}
            </td>
            </tr>
            <tr v-for="feature in module.features" :key="feature.id">
              <td style="text-align: left; width: 30%;">{{ feature.name }}</td>
              <td v-for="plan in feature.plans" :key="plan.planName" >
                              <span v-if="plan.value!=null">{{plan.value}} {{ plan.unit }}</span>
                              <span v-else>
                                <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'"
                                  style="color: #7c68ee;"></i>
                              </span>
                            </td>
              <td class="col-md-2"><span><i class="fa-solid fa-circle-check fa-lg"
                                  style="color: #7c68ee;"></i></span></td>

            </tr>
          </tbody>
        </table>
<!-- 
        <table class="table text-center" v-for="module in PlanFeatures" :key="module.moduleName">
          <tbody>
            <tr>
              <td colspan="5">
                <h2 class="accordion-header" :id="'heading' + module.moduleName">
                  <button class="accordion-button text-dark accordion-color-kj" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse' + module.moduleName" aria-expanded="true"
                    :aria-controls="'collapse' + module.moduleName">
                    <div class="container">
                      <div class="row" style="font-size: x-large;">
                        <p>{{ module.moduleName }}
                        </p>
                      </div>
                    </div>
                  </button>
                </h2>
                <div :id="'collapse' + module.moduleName" class="accordion-collapse collapse show">
                  <div class="accordion-body p-0 managementPartner-Acc-p-kj">
                    <table class="table text-center">
                      <thead>
                        <tr>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="feature in module.features" :key="feature.id">
                          <tr>
                            <td style="text-align: left; width: 30%;" class="col-md-2">{{ feature.name }}</td>
                            <td v-for="plan in feature.plans" :key="plan.planName" class="col-md-2">
                              <span>
                                <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'"
                                  style="color: #7c68ee;"></i>
                              </span>
                            </td>
                            <td class="col-md-2"><span><i class="fa-solid fa-circle-check fa-lg"
                                  style="color: #7c68ee;"></i></span></td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <!-- <div class="text-center" v-if="PlanDetails == false">
      <img src="../../public/assets/Images/Technical-Issue.png" style="width: 20%" class="my-5" />
      <h3 class="ms-5 mt-2">No Records Found</h3>
    </div> -->

    <!-- <tr>
                                  <td style="font-weight: 500; font-size:15px; text-align: left !important;">{{ item.name }}</td>
                                  <td v-for="plan in item.plans" :key="plan">
                                             <span>
                                        <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'" style="color: #7c68ee;"></i>
                                         </span>

                                     </td>
                                  <td><span><i class="fa-solid fa-circle-check fa-lg" style="color: #7c68ee;"></i></span></td>

                           
                                  </tr> -->


  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// import { encryptData, decryptData } from '@/platformFeatures/security'
import eventBus from "../store/eventBus";
export default {
  name: "BillingPlan",
  data() {
    return {
      PlanFeatures: [],
      allFeatures: [],
      PlanDetails: [],
      planType: false,
      show: false,
      data: [],
      billing: JSON.parse(localStorage.getItem('billingData'))

    };
  },
  async created() {    
    eventBus.emit('hide-button');
    this.currentPlan= this.billing.object.data.plan.planCode ;
    await this.planContent();

// console.log(this.currentPlan);
//     console.log(this.filteredPlans);
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    filteredPlans() {
      let type = this.planType ? 'Yearly' : 'Monthly';
      return this.PlanDetails.filter((plan) => plan.type === type);
    },
  },


  methods: {
    ...mapActions(['GetPlanCardDetails', 'getPaymentPage']),
    ...mapMutations(["setLoading"]),


    async planContent() {
      var planData = await this.GetPlanCardDetails({
        currency: ""
      });
      this.PlanFeatures = planData.object.data.features;
      this.PlanDetails = planData.object.data.allPlans;
      this.show = true;
    },
    async paymentPage(code) {
      const data = {
        subscription_id: this.billing.object.data.subscription.subScriptionId,
        plan: {
          plan_code: code,
        },
        subscriptionId : this.billing.object.data.subscription.subScriptionId
      };
      const response = await this.getPaymentPage(data);
      var url = await response.object.data.hostedpage.url;
      window.open(url, '_self');
    },
    fetchCurrency(data) {
      if (data == 'INR') {
        return '₹';
      } else {
        return '$';
      }
    },

  },
};
</script>
<style scoped>
.table-responsive {
  height: 600px;
  overflow-y: auto;
  display: block;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #f2f0ff;
  z-index: 2;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.UpgradeButton{
  border-radius: 5px !important;
  border: 1px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
  width: 180px;
  height: 35px;
  font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;

  }

.currentPlanButton{
  border-radius: 5px !important;
  border: 1px solid #7c68ee !important;
  background: #9a89ff !important;
  color: #fff !important;
  margin: 0px 6px 0 0;
  width: 180px;
  height: 35px;
  font-size: 16px;
  text-decoration: none;
  text-align: center !important; 
  padding-top: 5px;
  padding-bottom: 5px;
}
.UpgradeButton:hover {
  background: #7c68ee !important;
  color: #ffffff !important;
  margin: 0px 6px 0 0;
  text-decoration: none;

}


.planheading {
  color: #7c68ee;
  font-size: 25px;
  font-weight: 600;
  display: block
}

.custom-toggle {
  padding: 10px 25px;
  font-size: 20px;
}

.custom-toggle:checked {
  background-color: #7c68ee !important;

}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

/* .custom-toggle:not(:checked) {
  background-color: #1a1a1a !important; 
} */


/* .custom-toggle::after {
  background-color: #ff1616 !important;} */
</style>
